import { useTheme } from '@mui/material';
import {
  GridFooterContainerProps,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
  gridTopLevelRowCountSelector,
  selectedGridRowsCountSelector,
  gridFilteredTopLevelRowCountSelector,
  GridSelectedRowCount,
  GridRowCount,
  GridFooterContainer,
  FooterPropsOverrides,
} from '@mui/x-data-grid-pro';
import { forwardRef } from 'react';
import { AddStartupButton } from './AddStartupButton';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';

/**
 * Extends Data Grid implementation https://github.com/mui/mui-x/blob/v5.x/packages/grid/x-data-grid/src/components/GridFooter.tsx
 */
export const SearchGridFooter = forwardRef<
  HTMLDivElement,
  GridFooterContainerProps & FooterPropsOverrides
>(function GridFooter(props, ref) {
  const { isSharedPage } = useSharedPagesContext();
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const totalTopLevelRowCount = useGridSelector(
    apiRef,
    gridTopLevelRowCountSelector,
  );
  const selectedRowCount = useGridSelector(
    apiRef,
    selectedGridRowsCountSelector,
  );
  const visibleTopLevelRowCount = useGridSelector(
    apiRef,
    gridFilteredTopLevelRowCountSelector,
  );
  const showAddStartupButton =
    !isSharedPage && Boolean(visibleTopLevelRowCount);

  const theme = useTheme();
  const { setAddStartupInProgress, ...originalProps } = props;

  const selectedRowCountElement =
    !rootProps.hideFooterSelectedRowCount && selectedRowCount > 0 ? (
      <GridSelectedRowCount selectedRowCount={selectedRowCount} />
    ) : (
      <div />
    );

  const rowCountElement =
    !rootProps.hideFooterRowCount && !rootProps.pagination ? (
      <GridRowCount
        rowCount={totalTopLevelRowCount}
        visibleRowCount={visibleTopLevelRowCount}
      />
    ) : null;

  const paginationElement = rootProps.pagination &&
    !rootProps.hideFooterPagination &&
    rootProps.slots.pagination && (
      <rootProps.slots.pagination {...rootProps.slotProps?.pagination} />
    );

  return (
    <GridFooterContainer ref={ref} {...originalProps}>
      {showAddStartupButton && (
        <AddStartupButton
          setAddStartupInProgress={setAddStartupInProgress}
          sx={{
            marginLeft: theme.spacing(2),
          }}
        />
      )}
      {selectedRowCountElement}
      {rowCountElement}
      {paginationElement}
    </GridFooterContainer>
  );
});
