import { SxProps } from '@mui/material';
import {
  GetProjectsForImplementationsKanbanQueryVariables,
  GetStartupListItemsQueryVariables,
} from 'apollo/generated/sdkInnovationManager';
import {
  GetBreakdownDataQueryVariables,
  GetBusinessUnitPeopleQueryVariables,
  GetStartupOutboundRequestsQueryVariables,
} from 'apollo/generated/sdkShared';
import { HIDE_FROM_LIBRARY_TAG } from 'components/dashboard/startupList/constants';
import { GLASSDOLLAR_EMAIL_DOMAIN } from 'config';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { usePersonContext } from 'contexts/PersonContext';
import { usePrevious } from 'hooks/usePrevious';
import { produce } from 'immer';
import { isEmpty, isObject, omit } from 'lodash';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { GREY } from 'theme/palette';
import {
  ADOPTION_STAGES_FOR_KANBAN,
  LEAD_STAGES,
  POC_STAGES_FOR_KANBAN,
} from 'utils/projectStageEnum';

type ProjectFilterVariables =
  GetProjectsForImplementationsKanbanQueryVariables['filter'];

type ProjectFilter = {
  default: ProjectFilterVariables;
  status: ProjectFilterVariables;
  department: ProjectFilterVariables;
  owner: ProjectFilterVariables;
  gdManaged: ProjectFilterVariables;
  teams: ProjectFilterVariables;
  tags: ProjectFilterVariables;
  stage: ProjectFilterVariables;
  timeActive: ProjectFilterVariables;
};

type ProjectLeadsFilter = ProjectFilter & {
  maturityLevel: ProjectFilterVariables;
};

type StartupListFilterVariables = GetStartupListItemsQueryVariables['filter'];

type StartupListFilter = {
  default: StartupListFilterVariables;
  teams: StartupListFilterVariables;
  owner: StartupListFilterVariables;
  sourcingsOnly: StartupListFilterVariables;
  tags: StartupListFilterVariables;
  filterHidden: StartupListFilterVariables;
};

type StakeholderFilterVariables = GetBusinessUnitPeopleQueryVariables['filter'];

type StakeholderFilter = {
  default: StakeholderFilterVariables;
  teams: StakeholderFilterVariables;
  tags: StakeholderFilterVariables;
  department: StakeholderFilterVariables;
  maturity: StakeholderFilterVariables;
  userType: StakeholderFilterVariables;
  priority: StakeholderFilterVariables;
  innovationThemes: StakeholderFilterVariables;
};

type OutboundRequestsFilterVariables =
  GetStartupOutboundRequestsQueryVariables['filter'];

type OutboundRequestsFilter = {
  default: OutboundRequestsFilterVariables;
  initiatedBy: OutboundRequestsFilterVariables;
};

type AnalyticsProjectStagesFilter =
  GetBreakdownDataQueryVariables['projectStagesFilter'];

type AnalyticsPocStartedFilter =
  GetBreakdownDataQueryVariables['projectTimelineFilter'];

type AnalyticsFiltersByKey = {
  default: AnalyticsProjectStagesFilter;
  tags: AnalyticsProjectStagesFilter;
  teams: AnalyticsProjectStagesFilter;
  departments: AnalyticsProjectStagesFilter;
  category: AnalyticsProjectStagesFilter;
  dateRange: AnalyticsProjectStagesFilter;
};

export type ServerFilter = {
  projectLeadsFilters: ProjectLeadsFilter;
  projectPoCFilters: ProjectFilter;
  projectAdoptionsFilters: ProjectFilter;
  listTableFilters: StartupListFilter;
  stakeholderFilters: StakeholderFilter;
  outboundRequestsFilters: OutboundRequestsFilter;
  conversionRatesFilters: AnalyticsFiltersByKey;
  conversionRatesSummaryFilters: AnalyticsFiltersByKey;
  projectsByTimeFilters: AnalyticsFiltersByKey;
  operationalSummaryFilters: AnalyticsFiltersByKey;
  averageDaysInStagesFilters: AnalyticsFiltersByKey;
  breakdownSummaryFilters: AnalyticsFiltersByKey;
  sankeyFilters: AnalyticsFiltersByKey;
  projectsByCategoryFilters: AnalyticsFiltersByKey;
};

export type ProjectFilters = keyof Pick<
  ServerFilter,
  'projectAdoptionsFilters' | 'projectLeadsFilters' | 'projectPoCFilters'
>;

export type AnalyticsFilters = keyof Pick<
  ServerFilter,
  | 'conversionRatesFilters'
  | 'conversionRatesSummaryFilters'
  | 'projectsByTimeFilters'
  | 'operationalSummaryFilters'
  | 'averageDaysInStagesFilters'
  | 'breakdownSummaryFilters'
  | 'sankeyFilters'
  | 'projectsByCategoryFilters'
>;

const mapFilterKeysForAnalytics: {
  [key in ParentKeys]: string;
} = {
  projectAdoptionsFilters: 'Adoptions',
  projectLeadsFilters: 'Leads',
  projectPoCFilters: 'PoCs',
  listTableFilters: 'List Table',
  stakeholderFilters: 'Stakeholders',
  outboundRequestsFilters: 'Outbound Requests',
  conversionRatesFilters: 'Conversion Rates',
  conversionRatesSummaryFilters: 'Conversion Rates Summary',
  projectsByTimeFilters: 'Projects by Time',
  operationalSummaryFilters: 'Analytics Summary',
  averageDaysInStagesFilters: 'Average Days in Stages',
  breakdownSummaryFilters: 'Breakdown Summary',
  sankeyFilters: 'Sankey',
  projectsByCategoryFilters: 'Projects by Category',
};

export const autocompleteSx: SxProps = {
  minWidth: '200px',
  maxWidth: 'none',
  '& .MuiInputBase-root': {
    flexWrap: 'nowrap !important',
  },
  '& .MuiAutocomplete-clearIndicator': {
    background: GREY[300],
  },
  '& .MuiChip-deleteIcon': {
    width: 16,
    height: 16,
    padding: 0,
    flexShrink: 0,
    fontSize: 16,
  },
};

export type ParentKeys = keyof ServerFilter;
export type ChildKeys<T extends ParentKeys> = keyof ServerFilter[T];

type ContextType = {
  filters: ServerFilter;
  getFilter: <P extends ParentKeys, C extends ChildKeys<P>>(
    parentKey: P,
    targetKey: C,
  ) => ServerFilter[P][C];
  setFilter: <P extends keyof ServerFilter, C extends keyof ServerFilter[P]>(
    parentKey: P,
  ) => (targetKey: C, value: ServerFilter[P][C]) => void;
  resetFilter: <P extends ParentKeys>(parentKey: P) => void;
  getNormalizedFilters: <P extends ParentKeys>(
    parentKey: P,
    defaultOnly?: boolean,
  ) => ServerFilter[P]['default'];
};

const ServerFiltersContext = createContext<ContextType | null>(null);
const analyticsCommonFields = {
  default: {},
  tags: {},
  teams: {},
  departments: {},
  category: {},
  dateRange: {},
};

const initialFilters: ServerFilter = {
  listTableFilters: {
    default: {
      is_readonly: { _eq: false },
      source: { _neq: 'favourites' },
      status: { _eq: 'active' },
    },
    owner: {},
    teams: {},
    sourcingsOnly: {},
    tags: {},
    filterHidden: {
      _or: [
        { _not: { startup_list_tags: {} } },
        {
          startup_list_tags: { tag: { name: { _neq: HIDE_FROM_LIBRARY_TAG } } },
        },
      ],
    },
  },
  projectAdoptionsFilters: {
    default: {
      stage: { _in: ADOPTION_STAGES_FOR_KANBAN },
      source: {
        _nin: ['legacy_landscape', 'discovery_section', 'favourites'],
      },
    },
    status: {},
    department: {},
    owner: {},
    gdManaged: {},
    teams: {},
    tags: {},
    stage: {},
    timeActive: {},
  },
  projectPoCFilters: {
    default: {
      stage: { _in: POC_STAGES_FOR_KANBAN },
      source: {
        _nin: ['legacy_landscape', 'discovery_section', 'favourites'],
      },
    },
    status: {},
    department: {},
    owner: {},
    gdManaged: {},
    teams: {},
    tags: {},
    stage: {},
    timeActive: {},
  },
  projectLeadsFilters: {
    default: {
      stage: { _in: LEAD_STAGES },
      source: { _nin: ['legacy_landscape', 'discovery_section', 'favourites'] },
    },
    status: {},
    department: {},
    owner: {},
    gdManaged: {},
    teams: {},
    tags: {},
    stage: {},
    maturityLevel: {},
    timeActive: {},
  },
  stakeholderFilters: {
    default: {
      email: { _nilike: `%${GLASSDOLLAR_EMAIL_DOMAIN}%` },
      archived_at: { _is_null: true },
      _or: [{ _not: { user: {} } }, { user: { type: { _eq: 'bu_member' } } }],
    },
    tags: {},
    teams: {},
    department: {},
    maturity: {},
    priority: {},
    userType: {},
    innovationThemes: {},
  },
  outboundRequestsFilters: {
    default: {
      archived_at: { _is_null: true },
    },
    initiatedBy: {},
  },
  conversionRatesFilters: analyticsCommonFields,
  projectsByTimeFilters: analyticsCommonFields,
  operationalSummaryFilters: analyticsCommonFields,
  averageDaysInStagesFilters: analyticsCommonFields,
  conversionRatesSummaryFilters: analyticsCommonFields,
  breakdownSummaryFilters: analyticsCommonFields,
  sankeyFilters: analyticsCommonFields,
  projectsByCategoryFilters: analyticsCommonFields,
};

export const ServerFiltersProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { filter_by_team_enabled } = useCurrentOrganizationFromContext();
  const { person } = usePersonContext();

  const teamId = person?.team?.id;
  const previousTeamId = usePrevious(teamId);
  const [allFilters, setAllFilters] = useState<ServerFilter>(initialFilters);

  useEffect(() => {
    if (filter_by_team_enabled && teamId && teamId !== previousTeamId) {
      setAllFilters(prev =>
        produce(prev, draft => {
          draft.listTableFilters.teams = {
            team_id: { _in: [teamId] },
          };
          draft.projectPoCFilters.teams = {
            team_id: { _in: [teamId] },
          };
          draft.projectAdoptionsFilters.teams = {
            team_id: { _in: [teamId] },
          };
          draft.projectLeadsFilters.teams = {
            team_id: { _in: [teamId] },
          };
          draft.stakeholderFilters.teams = {
            team_id: { _in: [teamId] },
          };
        }),
      );
    }
  }, [filter_by_team_enabled, previousTeamId, teamId]);

  const setFilter = useCallback(
    <P extends ParentKeys, C extends ChildKeys<P>>(parentKey: P) => {
      return (targetKey: C, value: ServerFilter[P][C]) => {
        if (targetKey === 'default') return;

        setAllFilters(prev =>
          produce(prev, draft => {
            const section = draft[parentKey];
            if (section && typeof section === 'object') {
              section[targetKey] = value;
            }
          }),
        );

        captureAnalyticsEvent('Filters Applied', {
          filterArea: mapFilterKeysForAnalytics[parentKey],
          filterValue: targetKey as string,
        });
      };
    },
    [setAllFilters],
  );

  const getFilter = useCallback(
    <P extends ParentKeys, C extends ChildKeys<P>>(
      parentKey: P,
      targetKey: C,
    ) => {
      return allFilters[parentKey][targetKey];
    },
    [allFilters],
  );

  const resetFilter = useCallback(
    <P extends ParentKeys>(parentKey: P) => {
      setAllFilters(prev =>
        produce(prev, draft => {
          const section = draft[parentKey];
          if (isObject(section)) {
            Object.keys(section).forEach(key => {
              if (key !== 'default') {
                section[key as keyof ServerFilter[P]] =
                  initialFilters[parentKey][key as keyof ServerFilter[P]];
              }
            });
          }
        }),
      );
    },
    [setAllFilters],
  );

  const getNormalizedFilters = useCallback(
    <P extends ParentKeys>(parentKey: P, defaultOnly?: boolean) => {
      return Object.keys(allFilters[parentKey]).reduce(
        (acc, key) => {
          if (key !== 'default' && !!defaultOnly) return acc;

          const filter = allFilters[parentKey][key as keyof ServerFilter[P]];

          if (isEmpty(filter)) return acc;
          if (!acc._and) acc._and = [];

          acc._and.push(
            filter as
              | ProjectFilterVariables
              | StartupListFilterVariables
              | StakeholderFilterVariables
              | AnalyticsProjectStagesFilter
              | AnalyticsPocStartedFilter,
          );

          return acc;
        },
        {
          _and: [],
        } as never as ServerFilter[P]['default'],
      );
    },
    [allFilters],
  );

  return (
    <ServerFiltersContext.Provider
      value={{
        filters: allFilters,
        getFilter,
        setFilter,
        resetFilter,
        getNormalizedFilters,
      }}
    >
      {children}
    </ServerFiltersContext.Provider>
  );
};

export const useServerFiltersContext = <T extends ParentKeys>(scope: T) => {
  const state = useContext(ServerFiltersContext);

  if (!state) {
    throw new Error(
      'useServerFiltersContext must be used within a ServerFiltersProvider',
    );
  }

  const filters = state.filters[scope];
  const isFilterEmpty = Object.values(omit(filters, 'default')).every(isEmpty);
  const setFiltersMemoized = useMemo(
    () => state.setFilter(scope),
    [state, scope],
  );
  const getFilterMemoized = useCallback(
    (fnArgs: Parameters<typeof state.getFilter>) => state.getFilter(...fnArgs),
    [state],
  );
  const resetFilterMemoized = useCallback(
    (scope: ParentKeys) => state.resetFilter(scope),
    [state],
  );

  return {
    filters,
    isFilterEmpty,
    setFilters: setFiltersMemoized,
    getFilter: getFilterMemoized,
    normalizedFilter: state.getNormalizedFilters(scope),
    normalizedDefaultFilter: state.getNormalizedFilters(scope, true),
    resetFilter: resetFilterMemoized,
  };
};
