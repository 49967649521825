import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Paper,
  PaperProps,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DepartmentType } from 'components/engagement/people/AddPersonModal';
import BaseAddButtonForAutocomplete from 'components/base/BaseAddButtonForAutocomplete';
import React from 'react';

const AUTOCOMPLETE_STYLES = {
  formControl: {
    width: '100%',
    marginTop: 1,
  },
  textField: {
    '& .MuiInputBase-root': {
      backgroundColor: 'action.hover',
      borderTopLeftRadius: '8px !important',
      borderTopRightRadius: '8px !important',
      px: 1,
      py: 0.5,
    },
  },
  paper: {
    borderTopLeftRadius: '0 !important',
    borderTopRightRadius: '0 !important',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '210px',
  },
  unlinkButton: {
    minWidth: 'auto',
    padding: '2px 8px',
    fontSize: '0.75rem',
    marginLeft: 'auto',
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
} as const;

const filterDepartmentOptions = (
  options: DepartmentType[],
  inputValue: string,
  selectedValue: number | undefined,
  departments: DepartmentType[],
) => {
  const selectedOption = departments.find(d => d.id === selectedValue);
  const filteredOptions = options.filter(
    option =>
      option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
      (option.description?.toLowerCase().includes(inputValue.toLowerCase()) ??
        false),
  );

  if (
    selectedOption &&
    filteredOptions.some(opt => opt.id === selectedOption.id)
  ) {
    return [
      selectedOption,
      ...filteredOptions.filter(opt => opt.id !== selectedOption.id),
    ];
  }

  return filteredOptions;
};

interface DepartmentAutocompleteProps {
  departments: DepartmentType[];
  value: number | undefined;
  anchorEl: HTMLButtonElement | null;
  onDepartmentSelect: (departmentId: number | undefined) => void;
  onUnlinkDepartment: () => void;
  onNewDepartment: (e: React.MouseEvent) => void;
  onInputChange: (value: string) => void;
}

const DepartmentOption = ({
  option,
  props,
  state,
  value,
  unlinkDepartment,
  handleNewDepartment,
}: {
  option: DepartmentType;
  props: React.HTMLAttributes<HTMLLIElement>;
  state: { index: number };
  value: number | undefined;
  unlinkDepartment: () => void;
  handleNewDepartment: (e: React.MouseEvent) => void;
}) => {
  return (
    <>
      {state.index === 0 && (
        <BaseAddButtonForAutocomplete
          onClick={handleNewDepartment}
          text='New Department'
        />
      )}
      <Box
        component='li'
        {...props}
        key={option.id}
        data-testid={`department-${option.id}`}
        sx={AUTOCOMPLETE_STYLES.listItem}
      >
        <Stack spacing={0}>
          <Typography variant='body2'>{option.name}</Typography>
          {option.description && (
            <Typography
              variant='body2'
              color='text.secondary'
              sx={AUTOCOMPLETE_STYLES.description}
            >
              {option.description}
            </Typography>
          )}
        </Stack>
        {option.id === value && (
          <Button
            size='small'
            color='error'
            onClick={e => {
              e.stopPropagation();
              unlinkDepartment();
            }}
            sx={AUTOCOMPLETE_STYLES.unlinkButton}
          >
            Unlink
          </Button>
        )}
      </Box>
    </>
  );
};

export const DepartmentAutocomplete: React.FC<DepartmentAutocompleteProps> = ({
  departments,
  value,
  anchorEl,
  onDepartmentSelect,
  onUnlinkDepartment,
  onNewDepartment,
  onInputChange,
}) => {
  return (
    <FormControl variant='standard' sx={AUTOCOMPLETE_STYLES.formControl}>
      <Autocomplete<DepartmentType, false, false>
        options={departments}
        slots={{ paper: CustomPaper }}
        open={!!anchorEl}
        filterOptions={(options, { inputValue }) =>
          filterDepartmentOptions(options, inputValue, value, departments)
        }
        data-testid='add-person-modal__department'
        getOptionLabel={option => option.name}
        renderOption={(props, option, state) => (
          <DepartmentOption
            option={option}
            props={props}
            state={state}
            value={value}
            unlinkDepartment={onUnlinkDepartment}
            handleNewDepartment={onNewDepartment}
          />
        )}
        noOptionsText={
          <BaseAddButtonForAutocomplete
            text='New Department'
            divider={false}
            onClick={onNewDepartment}
          />
        }
        value={departments.find(d => d.id === value) || null}
        onChange={(_, value) => {
          onDepartmentSelect(value?.id);
        }}
        renderInput={params => (
          <TextField
            {...params}
            autoFocus
            variant='standard'
            placeholder='Search department'
            InputProps={{
              ...params.InputProps,
              endAdornment: null,
            }}
            sx={AUTOCOMPLETE_STYLES.textField}
          />
        )}
        onInputChange={(_, newInputValue) => {
          onInputChange(newInputValue);
        }}
      />
    </FormControl>
  );
};

const CustomPaper = (props: PaperProps) => (
  <Paper {...props} sx={AUTOCOMPLETE_STYLES.paper} />
);
