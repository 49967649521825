import { Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { StartupFormState } from './useStartupDetailsFormik';

export const LabelAndInputField = ({
  label,
  fieldName,
  valueFormatter,
}: {
  label: string;
  fieldType: string;
  valueFormatter?: (value: string) => string;
  fieldName:
    | 'founded_year'
    | 'hq'
    | 'employees_count'
    | 'funding'
    | 'name'
    | 'company_traction'
    | 'pricing';
}) => {
  const { values } = useFormikContext<StartupFormState>();

  const fieldValue = values[fieldName];

  if (!fieldValue) {
    return null;
  }

  return (
    <Stack gap={0.5}>
      <Typography
        sx={{
          color: 'grey.700',
        }}
        fontWeight={700}
      >
        {label}
      </Typography>
      <Typography variant='body2'>
        {valueFormatter ? valueFormatter(fieldValue.toString()) : fieldValue}
      </Typography>
    </Stack>
  );
};
