import { Box, Skeleton, Stack, TextField, Typography } from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterOperator,
} from '@mui/x-data-grid-pro';
import { useGetEntitiesLinkedToStartupQuery } from 'apollo/generated/sdkShared';
import { ExpandedGridCell } from 'components/ExpandedGridCell';
import TypographyWithEllipsis from 'components/TypographyWithEllipsis';
import { BaseCompanyDetails } from 'components/base/BaseCompanyDetails';
import { BaseStartupAvatar } from 'components/base/BaseStartupAvatar';
import {
  LinkedEntitiesType,
  LinkedToEntity,
} from 'components/dashboard/StartupInfoSidePanel/LinkedToEntity';
import StartupFunnelLabelChip from 'components/startups/StartupFunnelLabelChip';
import { useStartupSidePanel } from 'contexts/StartupSidePanelContext';
import { ChangeEvent, useMemo } from 'react';
import { DecoratedOrganizationStartup, RelatedList } from '.';
import { ListsLabel } from '../../../components/startupsPage/ListsLabel';

const TextFieldFilterComponent = (props: GridFilterInputValueProps) => {
  const { item, applyValue } = props;

  const handleFilterChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        paddingLeft: '20px',
      }}
    >
      <TextField
        id='text-input-filter'
        label='Value'
        variant='standard'
        onChange={handleFilterChange}
        value={item.value}
      />
    </Box>
  );
};

const listsFilterOperator: GridFilterOperator = {
  label: 'contains',
  value: 'contains',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }

    return (params: GridCellParams): boolean => {
      if (!params.value) return false;

      const lists = params.value as RelatedList;

      return lists!.some(list =>
        list.title.toLowerCase().includes(filterItem.value.toLowerCase()),
      );
    };
  },
  InputComponent: TextFieldFilterComponent,
  InputComponentProps: { type: 'string' },
};

export const getDataGridColumns = () => {
  const COLUMNS: GridColDef<DecoratedOrganizationStartup>[] = [
    {
      field: 'name',
      headerName: 'Startup',
      filterable: false,
      sortable: false,
      flex: 1,
      minWidth: 350,
      renderCell: function RenderName({ row: supplier }) {
        const { openStartupSidePanel } = useStartupSidePanel();

        return (
          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              openStartupSidePanel(supplier.id, {
                startupListId: undefined,
              })
            }
          >
            <BaseStartupAvatar
              startup={{
                logo_url: supplier.logo_url,
                name: supplier.name,
                domain: supplier.domain,
              }}
              size='medium'
            />
            <Stack>
              <Stack direction='row' alignItems='center' spacing={1}>
                <TypographyWithEllipsis
                  tooltipTitle={
                    <Typography variant='body2'>{supplier.name}</Typography>
                  }
                  width={290}
                  tooltipVariant='light'
                  variant='body2'
                  sx={({ palette, spacing }) => ({
                    color: palette.secondary.main,
                    cursor: 'pointer',
                    maxWidth: spacing(2),
                  })}
                >
                  {supplier.name}
                </TypographyWithEllipsis>
                <StartupFunnelLabelChip
                  label={supplier.funnel_label}
                  startupName={supplier.name as string}
                />
              </Stack>
              <BaseCompanyDetails
                company={{
                  employees_count: supplier.employees_count,
                  founded_year: supplier.founded_year,
                  funding: supplier.funding,
                  hq: supplier.hq,
                  signal: supplier.signal_label,
                  name: supplier.name,
                }}
              />
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: 'short_description',
      headerName: 'Description',
      filterable: false,
      sortable: false,
      flex: 1,
      minWidth: 300,
      renderCell: ({ value }) => {
        return (
          <ExpandedGridCell
            value={value || '-'}
            multiLineElipsis={true}
            numberOfLines={3}
          />
        );
      },
    },
    {
      field: 'linked_to',
      headerName: 'Linked to',
      flex: 1,
      minWidth: 300,
      getApplyQuickFilterFn: undefined,
      renderCell: params => (
        <RenderLinkedToCell params={{ value: params.row.id }} />
      ),
    },
    // Only visible for export purpose
    {
      field: 'listed_in',
      headerName: 'Listed in',
      flex: 1,
      minWidth: 300,
      getApplyQuickFilterFn: undefined,
      valueFormatter: (_, row) => {
        const value = row.listed_in as RelatedList;
        return value ? value.map(({ title }) => title).join(', ') : null;
      },
    },
    {
      field: 'shortlisted_in',
      headerName: 'Shortlisted in',
      flex: 1,
      minWidth: 220,
      valueFormatter: value => {
        return (value as RelatedList)?.map(({ title }) => title).join(', ');
      },
      renderCell: params => {
        const list = params.value as RelatedList;

        return (
          <Stack direction='row' spacing={1}>
            <ListsLabel lists={list?.map(l => l.title) || []} />
          </Stack>
        );
      },
      sortable: false,
      filterOperators: [listsFilterOperator],
    },
    {
      field: 'selected_in',
      headerName: 'Selected In',
      flex: 1,
      minWidth: 220,
      valueFormatter: value => {
        return (value as RelatedList)?.map(({ title }) => title).join(', ');
      },
      renderCell: params => {
        const list = params.value as RelatedList;
        return (
          <Stack direction='row' spacing={1}>
            <ListsLabel lists={list?.map(l => l.title) || []} />
          </Stack>
        );
      },
      sortable: false,
      filterOperators: [listsFilterOperator],
    },
    {
      field: 'created_at',
      headerName: 'Listed since',
      flex: 1,
      minWidth: 200,
      valueFormatter: value => {
        return value ? new Date(value).toLocaleDateString() : '-';
      },
    },
  ];
  return COLUMNS;
};

const RenderLinkedToCell = ({ params }: { params: { value: number } }) => {
  const cellValue = params.value;

  const { data: linkedEntitiesFetchResponse, loading } =
    useGetEntitiesLinkedToStartupQuery({
      variables: { startupId: cellValue! },
      fetchPolicy: 'cache-and-network',
    });

  const linkedEntitiesData = useMemo(
    () =>
      linkedEntitiesFetchResponse?.get_entities_linked_to_startup?.data
        ?.entities as LinkedEntitiesType | undefined,
    [
      linkedEntitiesFetchResponse?.get_entities_linked_to_startup?.data
        ?.entities,
    ],
  );

  if (loading) {
    return (
      <Stack direction='row' spacing={1} alignItems='center'>
        <Skeleton variant='text' height={24} width={280} />
      </Stack>
    );
  }

  if (!linkedEntitiesData) return null;

  return (
    <Stack
      sx={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
      onClick={e => e.stopPropagation()}
    >
      <LinkedToEntity
        hideHeader
        showOnlyTitle
        linkedEntitiesData={linkedEntitiesData}
      />
    </Stack>
  );
};
