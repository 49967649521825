import {
  Box,
  Button,
  ButtonGroup,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  GridCsvGetRowsToExportParams,
  GridToolbarExport,
  ToolbarPropsOverrides,
  gridPaginatedVisibleSortedGridRowEntriesSelector,
} from '@mui/x-data-grid-pro';
import { StandardSearchBar } from 'components/StyledSearchInput';

import { BaseAddStartupToListMenu } from 'components/base/BaseAddStartupToListMenu';
import { StartupFunnelLabel } from 'components/dashboard/StartupInfoSidePanel/StartupLabelChipSelector';
import useAuth from 'hooks/useAuth';

const FilterButton = (props: {
  count: number | undefined;
  text: string;
  label: StartupFunnelLabel;
  filterLabel: StartupFunnelLabel;
  setFilterLabel: (label: StartupFunnelLabel) => void;
}) => {
  const { palette } = useTheme();
  const filterLabelButtonSelected = {
    backgroundColor: palette.primary.main,
    color: palette.grey[100],
    '&:hover': {
      backgroundColor: palette.primary.dark,
    },
  };
  const filterLabelButtonInactive = {
    borderColor: palette.grey[500],
    color: palette.grey[700],
  };

  return (
    <Button
      onClick={() => props.setFilterLabel(props.label)}
      sx={
        props.filterLabel === props.label
          ? filterLabelButtonSelected
          : filterLabelButtonInactive
      }
    >
      {props.text}
      <Typography variant='inherit' marginLeft={0.5}>
        {props.count ? (
          `(${props.count})`
        ) : (
          <Skeleton
            variant='rounded'
            width={16}
            sx={{
              backgroundColor: ({ palette }) => palette.grey[300],
            }}
          />
        )}
      </Typography>
    </Button>
  );
};

export const CustomToolbar = ({
  searchStr,
  setSearchStr,
  filterLabel,
  setFilterLabel,
  startupsCountByLabel,
  selectedStartups,
}: ToolbarPropsOverrides) => {
  const { isStaff } = useAuth();
  const { palette } = useTheme();

  return (
    <Stack
      direction='row'
      sx={{
        padding: 1.5,
        width: '100%',
        backgroundColor: palette.background.neutral,
      }}
      justifyContent='space-between'
    >
      <Stack direction='row' alignItems='center'>
        <Box>
          <StandardSearchBar
            placeholder='Search by name, description, hq, or list title'
            onQueryChange={setSearchStr}
            query={searchStr}
            sx={{ maxWidth: 500, minWidth: 380 }}
          />
        </Box>
        {isStaff && (
          <GridToolbarExport
            printOptions={{
              disableToolbarButton: true,
            }}
            csvOptions={{
              delimiter: ';',
              utf8WithBom: true,
              fileName: 'startups-export',
              fields: [
                'name',
                'website',
                'short_description',
                'listed_in',
                'shortlisted_in',
                'selected_in',
                'created_at',
              ],
              getRowsToExport: (params: GridCsvGetRowsToExportParams) => {
                const rows = gridPaginatedVisibleSortedGridRowEntriesSelector(
                  params.apiRef,
                );

                return rows.map(row => row.id);
              },
            }}
          />
        )}
        {selectedStartups.length > 0 && (
          <BaseAddStartupToListMenu
            selectedStartups={selectedStartups}
            shouldAddMultiple
            analyticsSource='STARTUPS_CRM'
          />
        )}
      </Stack>
      <Stack direction='row' alignItems='center' spacing={1}>
        <ButtonGroup size='small'>
          <FilterButton
            count={startupsCountByLabel.InLibrary}
            text={'All'}
            label={'InLibrary'}
            filterLabel={filterLabel}
            setFilterLabel={setFilterLabel}
          />
          <FilterButton
            count={startupsCountByLabel.Connected}
            text={'Connected'}
            label={'Connected'}
            filterLabel={filterLabel}
            setFilterLabel={setFilterLabel}
          />
          <FilterButton
            count={startupsCountByLabel.Testing}
            text={'Testing'}
            label={'Testing'}
            filterLabel={filterLabel}
            setFilterLabel={setFilterLabel}
          />
          <FilterButton
            count={startupsCountByLabel.Tested}
            text={'Tested'}
            label={'Tested'}
            filterLabel={filterLabel}
            setFilterLabel={setFilterLabel}
          />
        </ButtonGroup>
      </Stack>
    </Stack>
  );
};
