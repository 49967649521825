import { Box, Card } from '@mui/material';
import {
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { captureException } from '@sentry/react';
import {
  GetOrganizationStartupsQuery,
  InputMaybe,
  OrganizationStartupsOrderBy,
  Suppliers,
  SuppliersBoolExp,
  useGetLabelsCountForStartupsPageQuery,
  useGetOrganizationStartupsQuery,
} from 'apollo/generated/sdkShared';
import { SelectedStartupsForAddToList } from 'components/base/BaseAddStartupToListMenu';
import { StartupFunnelLabel } from 'components/dashboard/StartupInfoSidePanel/StartupLabelChipSelector';
import EmptyContent from 'components/EmptyContent';
import { getStartupFunnelLabelOrderWithoutInLibrary } from 'components/startups/StartupFunnelLabelChip';
import {
  StartupSignal,
  getStartupSignalLabelByOrder,
} from 'components/startups/StartupSignalLabel';
import useLocalStorage from 'hooks/useLocalStorage';
import Page500 from 'pages/Page500';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { StartupConnectionStatus } from '../../../@types/shared';
import { StartupsDataGrid } from '../../../components/startupsPage/StartupsDataGrid';

import { CustomToolbar } from './CustomToolbar';
import { getDataGridColumns } from './getDataGridColumns';

declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides {
    searchStr: string;
    setSearchStr: Dispatch<SetStateAction<string>>;
    filterLabel: StartupFunnelLabel;
    setFilterLabel: Dispatch<SetStateAction<StartupFunnelLabel>>;
    startupsCountByLabel: {
      [key in StartupFunnelLabel]: number | undefined;
    };
    selectedStartups: SelectedStartupsForAddToList;
  }
}

export type RelatedList =
  | Array<{ id: number; title: string }>
  | null
  | undefined;

export type DecoratedOrganizationStartup = Pick<
  Suppliers,
  | 'id'
  | 'domain'
  | 'website'
  | 'name'
  | 'hq'
  | 'short_description'
  | 'long_description'
  | 'employees_count'
  | 'funding'
  | 'founded_year'
  | 'logo_url'
> & {
  created_at?: string | null;
  listed_in: RelatedList;
  selected_in: RelatedList;
  shortlisted_in: RelatedList;
  funnel_label?: StartupFunnelLabel | null;
  signal_label?: StartupSignal | null;
  connected: StartupConnectionStatus | 'not connected';
};

const PAGE_SIZE = 20;
const DEBOUNCED_TIME = 1000; // In milliseconds
const SORTABLE_COLUMNS = ['created_at'];

export default function StartupsPage() {
  const [searchStr, setSearchStr] = useState('');
  const [debouncedSearchStr, setDebouncedSearchStr] = useState(searchStr);
  const columns = useMemo(() => getDataGridColumns(), []);

  // Effect for debouncing search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchStr(searchStr);
    }, DEBOUNCED_TIME); // Delay for 2 seconds

    return () => {
      clearTimeout(handler);
    };
  }, [searchStr]); // Only re-run the effect if searchStr changes

  const [pageSize, setPageSize] = useLocalStorage(
    'library_startups_page_size',
    PAGE_SIZE,
  );

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize,
  });

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    [],
  );

  const [filterLabel, setFilterLabel] =
    useState<StartupFunnelLabel>('InLibrary');

  const queryOptions = useMemo(
    () => ({
      limit: paginationModel.pageSize,
      offset: paginationModel.page * paginationModel.pageSize,
    }),
    [paginationModel.page, paginationModel.pageSize],
  );

  const where = useMemo((): {
    startup: InputMaybe<SuppliersBoolExp>;
  } => {
    const iLikeSearchStr = `%${debouncedSearchStr}%`;
    const searchStrWhereQuery: InputMaybe<SuppliersBoolExp> = {
      _or: [
        { name: { _ilike: iLikeSearchStr } },
        { short_description: { _ilike: iLikeSearchStr } },
        { hq: { _ilike: iLikeSearchStr } },
        { listed_in_startup_lists: { title: { _ilike: iLikeSearchStr } } },
        { shortlisted_in_startup_lists: { title: { _ilike: iLikeSearchStr } } },
        { selected_in_startup_lists: { title: { _ilike: iLikeSearchStr } } },
      ],
    };

    return {
      startup: {
        ...(debouncedSearchStr ? searchStrWhereQuery : {}),
        funnel_labels: {
          value: {
            _eq: filterLabel,
          },
        },
      },
    };
  }, [filterLabel, debouncedSearchStr]);

  const [orderBy, setOrderBy] = useState<
    InputMaybe<Array<OrganizationStartupsOrderBy> | OrganizationStartupsOrderBy>
  >([{ created_at: 'desc' }]);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    const sorting = sortModel
      .map(sm => {
        if (SORTABLE_COLUMNS.includes(sm.field)) {
          return {
            created_at: sm.sort,
          };
        } else {
          return null;
        }
      })
      .filter(sm => sm !== null) as Array<OrganizationStartupsOrderBy>;
    // Here you save the data you need from the sort model

    setOrderBy(sorting);
  }, []);

  const {
    data: startupsData,
    previousData: previousStartupsData,
    error: getAllStartupsError,
    loading,
  } = useGetOrganizationStartupsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { ...queryOptions, where, orderBy },
  });

  const { data: filterStats } = useGetLabelsCountForStartupsPageQuery({
    fetchPolicy: 'cache-and-network',
  });

  const allStartups = useMemo(
    () =>
      startupsData?.organization_startups ||
      previousStartupsData?.organization_startups ||
      [],
    [
      startupsData?.organization_startups,
      previousStartupsData?.organization_startups,
    ],
  );

  const startupsCountByLabel = useMemo(() => {
    return {
      InLibrary: filterStats?.labeled_as_in_library?.aggregate?.count,
      Connected: filterStats?.labeled_as_connected?.aggregate?.count,
      Testing: filterStats?.labeled_as_testing?.aggregate?.count,
      Tested: filterStats?.labeled_as_tested?.aggregate?.count,
    };
  }, [
    filterStats?.labeled_as_in_library?.aggregate?.count,
    filterStats?.labeled_as_connected?.aggregate?.count,
    filterStats?.labeled_as_testing?.aggregate?.count,
    filterStats?.labeled_as_tested?.aggregate?.count,
  ]);

  const error = getAllStartupsError;

  const selectedStartups: SelectedStartupsForAddToList = useMemo(
    () =>
      selectionModel
        .map(
          id =>
            allStartups.find(startup => startup.startup?.id === id)?.startup,
        )
        .map(
          s =>
            ({
              id: s?.id || 0,
              name: s?.name || '',
              logo_url: s?.logo_url || '',
              crunchbase_url: '',
              website: s?.website || '',
              domain: s?.domain || '',
              short_description: s?.short_description || '',
              employees_count: s?.employees_count || 0,
              founded_year: s?.founded_year || 0,
              founded: s?.founded_year || 0,
              funding: s?.funding || 0,
              hq: s?.hq || '',
              linkedin_url: '',
              long_description: s?.long_description || '',
            }) as SelectedStartupsForAddToList[number],
        ),
    [selectionModel, allStartups],
  );

  const decoratedStartups = useMemo(
    () => getDecoratedStartups(allStartups),
    [allStartups],
  );

  if (error) {
    captureException(error);
    return <Page500 embedded />;
  }

  const handlePaginationModelChange = (
    paginationModel: GridPaginationModel,
  ) => {
    setPaginationModel(paginationModel);
    setPageSize(paginationModel.pageSize);
  };

  return (
    <Card sx={{ marginBottom: 10 }}>
      <StartupsDataGrid
        rows={decoratedStartups}
        columns={columns}
        pagination
        paginationModel={paginationModel}
        paginationMode='server'
        rowCount={startupsCountByLabel[filterLabel] || 0}
        pageSizeOptions={[20, 50, 200, 1000]}
        onPaginationModelChange={handlePaginationModelChange}
        slots={{
          toolbar: CustomToolbar,
          noRowsOverlay: () => NoRowsOverlay(searchStr),
        }}
        slotProps={{
          toolbar: {
            searchStr,
            setSearchStr,
            filterLabel,
            setFilterLabel,
            startupsCountByLabel,
            selectedStartups,
          },
        }}
        getRowHeight={() => 'auto'}
        sx={decoratedStartups.length === 0 ? { height: 600 } : undefined}
        checkboxSelection
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={(newSelectionModel: GridRowSelectionModel) =>
          setSelectionModel(newSelectionModel)
        }
        sortingMode='server'
        onSortModelChange={handleSortModelChange}
        loading={loading}
        keepNonExistentRowsSelected
        initialState={{
          sorting: {
            sortModel: [{ field: 'created_at', sort: 'desc' }],
          },
          columns: {
            columnVisibilityModel: {
              listed_in: false,
            },
          },
        }}
      />
    </Card>
  );
}

const NoRowsOverlay = (searchTerm: string) => (
  <Box sx={{ flexGrow: 1, height: 320 }}>
    <EmptyContent
      title={
        searchTerm
          ? `No results found matching "${searchTerm}"`
          : 'There are no startups yet'
      }
      img='/static/illustrations/illustration_empty_results.svg'
      sx={{ flexGrow: 1, height: 'auto' }}
    />
  </Box>
);

function getDecoratedStartups(
  allStartups: Required<GetOrganizationStartupsQuery['organization_startups']>,
): DecoratedOrganizationStartup[] {
  return (
    allStartups.map(({ startup, created_at }) => {
      if (!startup) throw new Error('Unexpected missing startup');
      if (!created_at) throw new Error('Unexpected missing startup');

      const connectionStatus = startup.connection?.status;
      const connected =
        connectionStatus === 'initiated'
          ? ('initiated' as const)
          : connectionStatus === 'accepted'
            ? ('accepted' as const)
            : ('not connected' as const);

      return {
        created_at,
        ...startup,
        listed_in: startup.listed_in_startup_lists,
        selected_in: startup.selected_in_startup_lists,
        shortlisted_in: startup.shortlisted_in_startup_lists,
        connected,
        signal_label: getStartupSignalLabelByOrder(startup.signal_labels),
        funnel_label: getStartupFunnelLabelOrderWithoutInLibrary(
          startup.funnel_labels,
        ),
      };
    }) || []
  );
}
