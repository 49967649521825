import { Stack, Typography } from '@mui/material';
import { useGetAllUsersQuery } from 'apollo/generated/sdkInnovationManager';
import { useUpsertStartupListOwnerMutation } from 'apollo/generated/sdkShared';
import { SelectItemField } from 'pages/dashboard/scoping/ProjectDetailBar';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useState } from 'react';
import { getListOwnerDetails } from 'utils/projects';
import { StartupListForDetail } from '../../../../@types/startupList';
import { useUpdateStartupListActivities } from '../useUpdateStartupListActivities';
import CollaboratorsList, { Collaborator } from './CollaboratorsList';
import { EditOwner } from './EditOwner';
import LinkListToLead from './LinkListToLead';
import SourcingCommentModal from './SourcingCommentModal';
import { StartupListTagsSelect } from './StartupListTagsSelect';

export const StartupListDetailBar = ({
  startupList,
  allowEdit,
  isSourcingInProgress,
}: {
  startupList: StartupListForDetail;
  allowEdit?: boolean;
  isSourcingInProgress?: boolean;
}) => {
  const usersData = useGetAllUsersQuery();
  const [upsertOwner] = useUpsertStartupListOwnerMutation();
  const [openSourcingComment, setOpenSourcingComment] = useState(false);
  const sourcingComment = startupList.sourcing_order?.comment;
  const { logStartupListActivity } = useUpdateStartupListActivities();

  const { isOwnedByGD, owner } = getListOwnerDetails({
    collaborators: startupList.collaborators,
  });

  const addOrUpdateOwner = async (userId: number) => {
    const owner = await upsertOwner({
      variables: {
        object: {
          startup_list_id: startupList.id,
          user_id: userId,
        },
      },
    });

    await logStartupListActivity({
      logs: [
        {
          action: 'updated',
          entityIds: userId,
          entityType: 'startup_list_collaborators',
        },
      ],
      startupListId: startupList.id,
    });

    captureAnalyticsEvent('StartupList Owner Added/Modified', {
      startupListCollaboratorId:
        owner?.data?.upsert_startup_list_owner?.startup_list_collaborator_id ??
        null,
    });
  };

  const hasOwner = startupList.collaborators.some(c => c.role === 'owner');

  const listCollaborators = startupList.collaborators.filter(c =>
    hasOwner ? c.role !== 'owner' : c.role !== 'creator',
  );
  return (
    <Stack
      direction='row'
      flexWrap='wrap'
      columnGap={theme => theme.spacing(5.5)}
      rowGap={theme => theme.spacing(1)}
      marginBottom={2}
      width='100%'
    >
      <SelectItemField label='Owner'>
        <EditOwner
          owner={owner}
          updateOwner={addOrUpdateOwner}
          options={
            usersData?.data?.users.map(u => ({
              ...u.person,
              id: u.id,
            })) || []
          }
          removeOwner={() => addOrUpdateOwner(0)}
          isOwnedByGD={isOwnedByGD}
        />
      </SelectItemField>
      {listCollaborators.length > 0 && (
        <SelectItemField label='Contributors'>
          <CollaboratorsList
            collaborators={startupList.collaborators
              .map(c => {
                const collaborator: Collaborator = {
                  updated_at: c.updated_at,
                  full_name: c.user.person.full_name,
                  email: c.user.person.email,
                };
                return collaborator;
              })
              .filter(
                stakeholder =>
                  stakeholder !== null && stakeholder !== undefined,
              )}
          />
        </SelectItemField>
      )}
      <SelectItemField label='Linked to'>
        <LinkListToLead
          startupList={{
            id: startupList.id,
            title: startupList.title,
            visibility: startupList.visibility,
            scope_id: startupList.scope_id,
          }}
        />
      </SelectItemField>
      {sourcingComment && !isSourcingInProgress && (
        <SelectItemField label='Sourcing comment'>
          <Typography
            onClick={() => setOpenSourcingComment(true)}
            sx={{
              '&:hover': {
                textDecoration: 'underline',
              },
              cursor: 'pointer',
              color: ({ palette }) => palette.info.main,
              fontSize: 14,
              lineHeight: '1.9',
              padding: '0 0.25rem',
            }}
          >
            Sourcing comment
          </Typography>
        </SelectItemField>
      )}
      <SelectItemField label='Tags'>
        <StartupListTagsSelect startupListId={startupList.id} />
      </SelectItemField>
      {sourcingComment && allowEdit && (
        <SourcingCommentModal
          isOpen={openSourcingComment}
          onHide={() => setOpenSourcingComment(false)}
          sourcingComment={sourcingComment}
        />
      )}
    </Stack>
  );
};
