import { Box, Card } from '@mui/material';
import { useCallback, useState } from 'react';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { SearchFilters, SearchV2Filter } from './SearchFilters';
import { SearchGridToolbar } from './SearchGridToolbar';
import { SearchCustomNoRowsOverlay } from './SearchCustomNoRowsOverlay';
import { SearchGridFooter } from './SearchGridFooter';
import { StartupsDataGrid } from 'components/startupsPage/StartupsDataGrid';
import AddStartupDialog from 'components/library/AddStartupDialog';
import { SEARCH_TYPES } from 'config';
import { useGetColumns } from './useGetColumns';
import useAuth from 'hooks/useAuth';
import WelcomeToScouter from './WelcomeToScouter';
import { LoadingSimilarStartups } from './LoadingSimilarStartups';
import { DecoratedScoutedStartup } from './types';
import { isOrganizationPortalURL } from 'utils/url';
import { SearchSubtitle } from './shared/SearchSubtitle';

declare module '@mui/x-data-grid' {
  interface FooterPropsOverrides {
    setAddStartupInProgress: () => void;
  }
  interface NoRowsOverlayPropsOverrides {
    setAddStartupInProgress: () => void;
  }
}

export const StartupResults = ({
  decoratedStartupResults,
  submittedQuery,
  loading,
  filter,
  handleFilterChange,
  search,
  embeddedInCurrentList,
}: {
  decoratedStartupResults: DecoratedScoutedStartup[] | null;
  submittedQuery: string;
  loading: boolean;
  filter: SearchV2Filter;
  handleFilterChange: (
    key: keyof SearchV2Filter,
    value: string | string[],
  ) => void;
  search: (query: string) => void;
  embeddedInCurrentList: { listId: number; categoryId?: number | null } | null;
}) => {
  const gridAPIRef = useGridApiRef();
  const { user } = useAuth();
  const isValidOrganizationPortalURL = isOrganizationPortalURL();
  const isOrganizationPortal =
    isValidOrganizationPortalURL || user.type === 'bu_member';
  const [addStartupInProgress, setAddStartupInProgress] = useState(false);

  const onShowSimilarClick = useCallback(
    (startupDomain: string) => {
      search(`${SEARCH_TYPES.similarTo}:${startupDomain}`);
    },
    [search],
  );

  const { columns, pinnedRightColumn, row } = useGetColumns({
    onShowSimilarClick,
    query: submittedQuery,
    isOrganizationPortal,
    embeddedInCurrentList: embeddedInCurrentList,
  });

  if (!decoratedStartupResults && !submittedQuery) {
    return (
      <Box marginTop={2}>
        <WelcomeToScouter onSubmit={search} />
      </Box>
    );
  }

  if (!decoratedStartupResults) {
    return <LoadingSimilarStartups />;
  }

  return (
    <>
      <SearchSubtitle sx={{ marginTop: 4.5 }}>Startups by topic</SearchSubtitle>
      <SearchFilters filter={filter} handleFilterChange={handleFilterChange} />
      <Card
        sx={{
          marginBottom: 2,
          position: 'relative',
        }}
      >
        <StartupsDataGrid
          rows={decoratedStartupResults}
          loading={loading}
          getRowId={row => row.dataGridId}
          columns={columns}
          initialState={{
            pinnedColumns: { right: pinnedRightColumn },
            pagination: { paginationModel: { pageSize: 50 } },
          }}
          pagination
          rowHeight={80}
          apiRef={gridAPIRef}
          slots={{
            toolbar: SearchGridToolbar,
            footer: SearchGridFooter,
            noRowsOverlay: SearchCustomNoRowsOverlay,
          }}
          slotProps={{
            row,
            noRowsOverlay: {
              setAddStartupInProgress: () => setAddStartupInProgress(true),
            },
            footer: {
              setAddStartupInProgress: () => setAddStartupInProgress(true),
            },
          }}
          sx={
            decoratedStartupResults.length === 0 ? { height: 600 } : undefined
          }
        />
      </Card>
      {addStartupInProgress && (
        <AddStartupDialog
          open={addStartupInProgress}
          handleClose={() => setAddStartupInProgress(false)}
        />
      )}
    </>
  );
};
