import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { EnumTableProjectStakeholderRolesEnum } from 'apollo/generated/sdkInnovationManager';
import { useAddNewChallengeStakeholderMutation } from 'apollo/generated/sdkShared';
import { Form, FormikProvider, useFormik } from 'formik';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { captureException } from '@sentry/react';
import { useSnackbar } from 'notistack';
import { useApolloClient } from '@apollo/client';
import { useGetChallengeAndConnections } from 'hooks/useGetChallengeAndConnections';
import { BaseStakeholdersSelect } from 'components/base/stakeholdersSelect/BaseStakeholdersSelect';
import {
  ALLOWED_PROJECT_ROLES,
  getStakeholderRole,
  PROJECT_ROLES,
} from 'utils/getStakeholderRole';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';

export const AddChallengeStakeholderModal = ({
  challengeId,
  challengeStakeholderIds,
  open,
  onHide,
}: {
  challengeId: number;
  challengeStakeholderIds: number[];
  open: boolean;
  onHide: () => void;
}) => {
  const { subdomain } = useCurrentOrganizationFromContext();
  const { PROJECT_ROLES_MAP } = getStakeholderRole({ subdomain });
  const { cache: apolloCache } = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const { refetch } = useGetChallengeAndConnections({
    challengeID: challengeId,
  });

  const [addNewChallengeStakeholder] = useAddNewChallengeStakeholderMutation();

  const handleProjectStakeholderChange = (id: number | null) => {
    setFieldValue('stakeholderId', id);
  };

  const addChallengeStakeholder = async ({
    stakeholderId,
    role,
  }: {
    stakeholderId?: number;
    role: keyof PROJECT_ROLES | '';
  }) => {
    try {
      if (stakeholderId) {
        await addNewChallengeStakeholder({
          variables: {
            challengeId: challengeId,
            personId: stakeholderId,
            role: role as EnumTableProjectStakeholderRolesEnum,
          },
        });
        refetch();

        apolloCache.evict({
          id: apolloCache.identify({
            __typename: 'challenges',
            id: challengeId,
          }),
        });

        apolloCache.gc();

        enqueueSnackbar('Stakeholder added.', {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar('Error adding stakeholder', {
        variant: 'error',
      });
      captureException(e);
    }
  };

  const formik = useFormik<{
    stakeholderId?: number;
    role: keyof PROJECT_ROLES | '';
  }>({
    initialValues: {
      stakeholderId: undefined,
      role: '',
    },
    onSubmit: async (values, { resetForm }) => {
      addChallengeStakeholder(values);
      if (values.stakeholderId && values.role) {
        captureAnalyticsEvent('Challenge stakeholder added', {
          challengeId: challengeId,
          stakeholderRole: values.role,
        });
      }
      resetForm();
      onHide();
    },
  });
  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  return (
    <Dialog open={open} fullWidth onClose={onHide}>
      <FormikProvider value={formik}>
        <Form autoComplete='off' onSubmit={handleSubmit}>
          <DialogTitle>Add stakeholder</DialogTitle>
          <DialogContent sx={{ marginTop: '25px' }}>
            <Stack direction='column' spacing={2} sx={{ marginTop: 1 }}>
              <BaseStakeholdersSelect
                previouslySelectedStakeholderIds={challengeStakeholderIds}
                previouslySelectedMessage='ADDED'
                selectedStakeholderId={getFieldProps('stakeholderId').value}
                onChange={stakeholder => {
                  handleProjectStakeholderChange(stakeholder?.id || null);
                }}
                placeholder='Search for stakeholder by name'
              />
              <TextField
                select
                fullWidth
                label='Role'
                defaultValue={null}
                {...getFieldProps('role')}
                helperText={touched.role && errors.role}
              >
                {ALLOWED_PROJECT_ROLES.map((value, index) => (
                  <MenuItem key={`${value} + ${index}`} value={value}>
                    {PROJECT_ROLES_MAP[value as keyof PROJECT_ROLES]}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant='text' color='inherit' onClick={onHide}>
              Cancel
            </Button>
            <Button
              type='submit'
              variant='contained'
              disabled={!formik.values.role || !formik.values.stakeholderId}
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};
