import { Autocomplete, TextField, Typography, Box, Chip } from '@mui/material';
import { orderBy } from 'lodash';
import { getCountryFlag, getCountryName } from 'utils/formatLocation';
import { useCallback } from 'react';
import { COUNTRY_CODES_ISO_3 } from 'utils/countryCodesIso3';
import uFuzzy from '@leeoniya/ufuzzy';

type CountryOption = {
  value: string;
  label: string;
  aliases: string[];
  category: 'Most picked' | 'More';
};

const MOST_PICKED_COUNTRIES = [
  'USA',
  'CAN',
  'DEU',
  'GBR',
  'FRA',
  'ESP',
  'ITA',
  'NLD',
  'IND',
  'CHN',
  'KOR',
  'AUS',
  'JPN',
];

const COUNTRY_ALIASES: Record<
  string,
  {
    nameOverride?: string;
    aliases: string[];
  }
> = {
  USA: {
    nameOverride: 'United States',
    aliases: ['USA', 'United States of America', 'America'],
  },
  DEU: {
    aliases: ['Germany', 'Deutschland'],
  },
  GBR: {
    aliases: ['GB', 'United Kingdom', 'UK'],
  },
  NLD: {
    nameOverride: 'Netherlands',
    aliases: ['NL', 'Holland', 'The Netherlands'],
  },
  CHN: {
    nameOverride: 'China',
    aliases: ['CN', '中国'],
  },
  KOR: {
    nameOverride: 'Korea',
    aliases: ['KR', 'South Korea'],
  },
};

const _COUNTRY_OPTIONS = COUNTRY_CODES_ISO_3.map(alpha3Code => {
  const country =
    COUNTRY_ALIASES[alpha3Code]?.nameOverride || getCountryName(alpha3Code);

  return {
    value: alpha3Code,
    label: country,
    aliases: COUNTRY_ALIASES[alpha3Code]?.aliases || [],
    category: MOST_PICKED_COUNTRIES.includes(alpha3Code)
      ? ('Most picked' as const)
      : ('More' as const),
  };
});

const ORDERED_COUNTRY_OPTIONS = orderBy(
  _COUNTRY_OPTIONS,
  [option => (option.category === ('Most picked' as string) ? 0 : 1), 'label'],
  ['asc', 'asc'],
);

export const SearchCountryFilter = ({
  value,
  onValueChange,
  onFocus,
}: {
  value: string[];
  onValueChange: (value: string[]) => void;
  onFocus: () => void;
}) => {
  const filteredItems = useCallback((searchText: string) => {
    if (!searchText) return ORDERED_COUNTRY_OPTIONS;
    const fuzzyMatch = new uFuzzy({
      intraIns: 1,
    }).search(
      ORDERED_COUNTRY_OPTIONS.map(i => `${i.label}|${i.aliases.join('|')}`),
      searchText,
    );
    return (
      fuzzyMatch[0]?.map((index: number) => ORDERED_COUNTRY_OPTIONS[index]) ||
      []
    );
  }, []);

  return (
    <Autocomplete
      sx={{
        width: '100%',
      }}
      multiple
      disablePortal
      size='small'
      options={ORDERED_COUNTRY_OPTIONS}
      onFocus={onFocus}
      filterOptions={(options, params) => {
        const filtered = options.filter(option => {
          if (params.inputValue === '') {
            return true;
          }

          return filteredItems(params.inputValue).includes(option);
        });

        if (filtered.length === 0) {
          return [];
        }

        return filtered;
      }}
      noOptionsText='No countries found'
      renderTags={(selectedOptions, getTagProps) => {
        return selectedOptions.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            label={option.label}
            key={option.value}
          />
        ));
      }}
      groupBy={(option: CountryOption) => option.category}
      renderOption={(props, option) => (
        <Box
          component={'li'}
          sx={{ width: '100%' }}
          {...props}
          key={option.value}
        >
          <Typography variant='body2' noWrap sx={{ marginRight: 0.5 }}>
            {getCountryFlag(option.value)}
          </Typography>
          <Typography variant='body2' noWrap>
            {option.label}
          </Typography>
        </Box>
      )}
      value={value.map(
        countryCode =>
          ORDERED_COUNTRY_OPTIONS.find(option => option.value === countryCode)!,
      )}
      onChange={(_, options) =>
        onValueChange(options.map(option => option.value))
      }
      renderInput={params => <TextField {...params} label='Country' />}
    />
  );
};
