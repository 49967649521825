import { DOMAIN_COLORS } from 'theme/palette';
import { EnumTableProjectHealthStatusesEnum } from 'apollo/generated/sdkInnovationManager';

export type StatusMap = {
  [key in EnumTableProjectHealthStatusesEnum]: string;
};

export const STATUS_COLOR_MAPPING: StatusMap = {
  green: DOMAIN_COLORS.projectStatus.green,
  amber: DOMAIN_COLORS.projectStatus.amber,
  red: DOMAIN_COLORS.projectStatus.red,
};

export const PROJECT_STATUSES: EnumTableProjectHealthStatusesEnum[] = [
  'green',
  'amber',
  'red',
];

export const HIDE_FROM_LIBRARY_TAG = 'One-startup lead';
