import { CloseOutlined, Star, ThumbUpAlt } from '@mui/icons-material';
import { Chip, Tooltip, useTheme } from '@mui/material';
import { NAV_TEXT_GREY } from 'components/NavSection';

export function StartupListFeatureBox({
  editModeEnabled,
  featureText,
  onDelete,
  onClick,
  mustHave,
}: {
  editModeEnabled?: boolean;
  featureText: string;
  onDelete?: () => void;
  onClick?: () => void;
  mustHave?: boolean;
}) {
  const { spacing } = useTheme();

  return (
    <Chip
      onClick={onClick}
      label={
        <Tooltip
          title={featureText.length > 40 ? featureText : ''}
          placement='top'
        >
          <span>
            {featureText.length > 40
              ? `${featureText.slice(0, 40)}...`
              : featureText}
          </span>
        </Tooltip>
      }
      variant='outlined'
      onDelete={editModeEnabled ? onDelete : undefined}
      sx={{
        background: '#EEEFF1',
        borderRadius: spacing(1),
        border: 0,
        height: spacing(3.5),
      }}
      icon={
        mustHave ? (
          <Star
            fontSize='small'
            sx={{
              '& svg, & path': { fill: NAV_TEXT_GREY },
              marginLeft: 0.5,
            }}
          />
        ) : (
          <ThumbUpAlt
            fontSize='small'
            sx={{
              '& svg, & path': { fill: NAV_TEXT_GREY },
              marginLeft: 0.5,
            }}
          />
        )
      }
      deleteIcon={
        <CloseOutlined
          fontSize='small'
          sx={({ spacing }) => ({
            width: spacing(2),
            height: spacing(2),
            fill: NAV_TEXT_GREY,
            marginRight: 1,
          })}
        />
      }
    />
  );
}
