import { Box } from '@mui/material';
import {
  GetOrganizationBySubdomainDocument,
  useGetOrganizationBySubdomainQuery,
} from 'apollo/generated/sdkShared';
import LoadingScreen from 'components/LoadingScreen';
import {
  GLASSDOLLAR_DEFAULT_FROM_EMAIL,
  GLASSDOLLAR_DEFAULT_FROM_NAME,
  GLASSDOLLAR_DEFAULT_REPLY_TO,
  X_HASURA_ORGANIZATION_UUID,
} from 'config';
import { ReactNode, createContext, useContext } from 'react';
import { CurrentOrganization } from '../@types/shared';
import { useOrganizationUUIDContext } from './OrganizationUUIDContext';

export const ORGANIZATION_QUERY_FOR_REFRESH =
  GetOrganizationBySubdomainDocument;

const CurrentOrganizationContext = createContext<CurrentOrganization | null>(
  null,
);

export const CurrentOrganizationContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const currentOrganization = useFetchCurrentOrganization();

  if (!currentOrganization) {
    return (
      <Box sx={{ height: '100vh', justifyContent: 'center' }}>
        <LoadingScreen />
      </Box>
    );
  }

  return (
    <CurrentOrganizationContext.Provider value={currentOrganization}>
      {children}
    </CurrentOrganizationContext.Provider>
  );
};

export const useCurrentOrganizationFromContext = () => {
  const organization = useContext(CurrentOrganizationContext);
  if (!organization) {
    throw new Error(
      'using CurrentOrganizationContext without the appropriate provider',
    );
  }
  return organization;
};

const useFetchCurrentOrganization = () => {
  const { organizationUUID } = useOrganizationUUIDContext();

  const {
    data: currentOrganizationData,
    loading: loadingOrganizationData,
    error,
  } = useGetOrganizationBySubdomainQuery({
    context: {
      headers: {
        // TODO: Should not fetch by UUID
        [X_HASURA_ORGANIZATION_UUID]: organizationUUID,
      },
    },
  });

  if (error)
    throw Error(
      `Errors while fetching current organization ${JSON.stringify(error)}`,
    );

  if (loadingOrganizationData || !currentOrganizationData) return null;

  const currentOrganization = currentOrganizationData.organizations[0];

  return {
    ...currentOrganization,
    from_email:
      currentOrganization.from_email || GLASSDOLLAR_DEFAULT_FROM_EMAIL,
    from_name: currentOrganization.from_email || GLASSDOLLAR_DEFAULT_FROM_NAME,
    reply_to_email:
      currentOrganization.from_email || GLASSDOLLAR_DEFAULT_REPLY_TO,
    project_solution_categories:
      currentOrganization.project_solution_categories || [
        {
          id: 1,
          name: 'Process Innovation',
          value: 'process_innovation',
        },
        {
          id: 2,
          name: 'Product Innovation',
          value: 'product_innovation',
        },
      ],
  };
};
