import { Box, Stack } from '@mui/material';
import EmptyContent from 'components/EmptyContent';
import { AddStartupButton } from './AddStartupButton';
import { NoRowsOverlayPropsOverrides } from '@mui/x-data-grid-pro';

export const SearchCustomNoRowsOverlay = ({
  setAddStartupInProgress,
}: NoRowsOverlayPropsOverrides) => {
  return (
    <EmptyContent
      img={'/static/search-not-found.svg'}
      title={
        <Stack
          component='div'
          sx={{
            // This allow the content to be selectable/clickable
            zIndex: 5,
          }}
          spacing={2}
          direction='column'
          alignItems='center'
        >
          <Box component='span'>
            {/* eslint-disable-next-line */}
            We couldn't find what you searched for. Try searching again with
            another keyword or
          </Box>
          <AddStartupButton setAddStartupInProgress={setAddStartupInProgress} />
        </Stack>
      }
    />
  );
};
