import {
  Box,
  Chip,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useGetOrganizationStartupContactsQuery } from 'apollo/generated/sdkShared';
import { ExpandedGridCell } from 'components/ExpandedGridCell';
import StyledDataGrid from 'components/StyledDataGrid';
import { EmailPersonCell } from 'components/base/EmailPersonCell';
import LinkToPerson from 'components/engagement/people/LinkToPerson';
import { isString } from 'lodash';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { SocialLinkIcon } from './SocialLinkIcon';
import SendEmailButton from 'components/shared/SendEmailButton';
import LightTooltip from 'components/LightTooltip';
import { fDate } from 'utils/datetime';
import EmptyContent from 'components/EmptyContent';
import { useRightTableActions } from 'components/base/useRightTableActions';
import { Edit as EditIcon } from '@mui/icons-material';
import { OrganizationStartupContactType } from '../../../@types/organizationStartupContact';
import { EditContactModal } from 'components/organizationStartupContacts/EditContactModal';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';

export function useGetDataGridColumns({
  setEditContact,
}: {
  setEditContact: Dispatch<
    SetStateAction<OrganizationStartupContactType | null>
  >;
}) {
  const { getActionsColumn, pinnedRightColumn, row } = useRightTableActions();
  const { isSharedPage } = useSharedPagesContext();

  const contactColumns: GridColDef[] = useMemo(() => {
    const columns: GridColDef[] = [
      { field: 'id', filterable: false },
      {
        field: 'contact_name_field',
        headerName: 'Name',
        filterable: false,
        minWidth: 200,
        flex: 2,
        renderCell: params => {
          const { name, is_primary_contact } = params.value;
          if (!isString(name)) return null;
          return (
            <Stack
              width={'100%'}
              direction='row'
              alignItems={'center'}
              paddingY={0.5}
              paddingRight={1}
            >
              {is_primary_contact && (
                <Chip
                  sx={{ marginRight: 1, fontWeight: 'bold' }}
                  label='Primary'
                  size='small'
                  color='primary'
                />
              )}
              <ExpandedGridCell
                value={name}
                multiLineElipsis={true}
                numberOfLines={1}
              />
            </Stack>
          );
        },
      },
      {
        field: 'title',
        headerName: 'Title',
        filterable: false,
        minWidth: 130,
        flex: 1,
      },
      {
        field: 'contact_info',
        headerName: 'Contact',
        filterable: false,
        width: 90,
        renderCell: params => {
          const { email, linkedin_url } = params.value;

          return (
            <Stack
              paddingX={1}
              spacing={0.25}
              direction='row'
              justifyContent='space-evenly'
            >
              <LightTooltip title={`Send email to ${email}`}>
                <Box>
                  <SendEmailButton emails={[email]} />
                </Box>
              </LightTooltip>
              {linkedin_url && (
                <SocialLinkIcon
                  fieldName={'linkedin'}
                  socialURL={linkedin_url}
                />
              )}
            </Stack>
          );
        },
      },
      {
        field: 'connected_since',
        headerName: 'Connected since',
        type: 'dateTime',
        minWidth: 135,
        valueFormatter: value => {
          return value ? fDate(value as string) : '-';
        },
      },
      {
        field: 'note',
        headerName: 'Note',
        filterable: false,
        minWidth: 170,
        flex: 3.5,
        renderCell: params => {
          return (
            <ExpandedGridCell
              value={params.value || '-'}
              multiLineElipsis={true}
              numberOfLines={2}
            />
          );
        },
      },
      {
        field: 'created_by',
        headerName: 'Internal Owner',
        minWidth: 120,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          if (!params.value) return <Typography variant='body2'>-</Typography>;
          if (!params.value.person)
            return <EmailPersonCell value={params.value.person} />;

          return (
            <LinkToPerson
              fullName={params.value.person.full_name || ''}
              id={params.value.person.id}
            />
          );
        },
      },
    ];
    if (!isSharedPage) {
      columns.push(
        getActionsColumn({
          minWidth: 30,
          sx: { width: 35, height: 35, padding: 0 },
          renderCellNode: params => (
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              width='100%'
            >
              <IconButton
                size='small'
                onClick={e => {
                  e.stopPropagation();
                  setEditContact(params.row);
                }}
              >
                <EditIcon fontSize='small' />
              </IconButton>
            </Stack>
          ),
        }),
      );
    }
    return columns;
  }, [getActionsColumn, isSharedPage, setEditContact]);
  return { contactColumns, pinnedRightColumn, row };
}

export const StartupInfoSidePanelContacts = ({
  startupId,
}: {
  startupId: number;
}) => {
  const { data: contactsData, loading: contactsLoading } =
    useGetOrganizationStartupContactsQuery({ variables: { startupId } });

  const [editContact, setEditContact] =
    useState<OrganizationStartupContactType | null>(null);

  const { contactColumns, pinnedRightColumn, row } = useGetDataGridColumns({
    setEditContact,
  });

  const contactRows = useMemo(
    () =>
      contactsData?.organization_startup_contacts.map(contact => ({
        ...contact,
        contact_name_field: {
          name: contact.full_name,
          is_primary_contact: contact.is_primary_contact,
        },
        contact_info: {
          email: contact.email,
          linkedin_url: contact.linkedin_url,
        },
      })) || [],
    [contactsData?.organization_startup_contacts],
  );

  return (
    <>
      {contactsLoading ? (
        <Skeleton />
      ) : contactRows.length > 0 ? (
        <StyledDataGrid
          isRowClickable={false}
          disableRowSelectionOnClick
          columns={contactColumns}
          rows={contactRows}
          hideFooter
          showCellVerticalBorder
          showColumnVerticalBorder
          pagination
          initialState={{
            pinnedColumns: { right: pinnedRightColumn },
            columns: {
              columnVisibilityModel: { id: false },
            },
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          slotProps={{ row }}
          sx={{
            '&, [class^=MuiDataGrid]': { borderTop: 'none' },
          }}
        />
      ) : (
        <EmptyContent
          description='No contacts yet. Be the first to add one.'
          img='/static/illustrations/illustration_empty_content.svg'
          sx={{ flexGrow: 1, height: 'auto' }}
          imageSx={{ height: 120, marginBottom: 3 }}
        />
      )}
      <EditContactModal
        startupId={startupId}
        contactInfo={editContact}
        open={Boolean(editContact)}
        onHide={() => setEditContact(null)}
      />
    </>
  );
};
