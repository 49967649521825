import {
  Box,
  Card,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { StartupConnectionStatus } from '../../../../@types/shared';
import { TypeSafeStyledDataGrid } from 'components/StyledDataGrid';
import {
  useGetStartupOutboundRequestsQuery,
  useUpdateStartupOutreachStatusMutation,
  useVerifyStartupOutreachMutation,
} from 'apollo/generated/sdkShared';
import { useStartupSidePanel } from 'contexts/StartupSidePanelContext';
import {
  GridColDef,
  GridSortModel,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';
import TypographyWithEllipsis from 'components/TypographyWithEllipsis';
import BaseLabel from 'components/base/BaseLabel';
import { BaseStartupAvatar } from 'components/base/BaseStartupAvatar';
import { BaseCompanyDetails } from 'components/base/BaseCompanyDetails';
import createColumn from 'components/base/dataGrid/createColumn';
import { Dispatch, SetStateAction, useState } from 'react';
import EmptyContent from 'components/EmptyContent';
import { useRightTableActions } from 'components/base/useRightTableActions';
import { captureException } from '@sentry/react';
import { useSnackbar } from 'notistack';
import {
  Delete as DeleteIcon,
  ScheduleSend as FollowUpIcon,
  HowToReg as VerifyConnectionIcon,
} from '@mui/icons-material';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import LinkToPerson from 'components/engagement/people/LinkToPerson';
import { GDLogo } from 'components/shared/GDLogo';
import { ArchiveRequestModal } from './ArchiveRequestModal';
import { formatDistance } from 'date-fns';
import { ConnectedProjectCell } from 'components/dashboard/startupList/list/ListTable';
import { GLASSDOLLAR_EMAIL_DOMAIN } from 'config';
import {
  OutboundRequestsColumnType,
  OutboundRequestsContactDetailsType,
  OutreachLabelColorType,
} from '../types';
import { BaseServerFilterMenu } from 'components/base/serverFilters/BaseServerFilterMenu';
import { InitiatedByFilter } from './filters/InitiatedByFilter';
import { useServerFiltersContext } from 'components/base/serverFilters/BaseServerFiltersContext';

const DEFAULT_SORTING_MODEL = [
  {
    field: 'status',
    sort: 'asc' as const,
  },
  {
    field: 'created_at',
    sort: 'desc' as const,
  },
];

export const getOutreachLabelInfo = (status: StartupConnectionStatus) => {
  let labelColor: OutreachLabelColorType;
  let labelText: string;

  switch (status) {
    case 'accepted':
      labelColor = 'primary';
      labelText = 'Accepted';
      break;
    case 'follow_up':
      labelColor = 'info';
      labelText = 'Follow Up';
      break;
    default:
      labelColor = 'warning';
      labelText = 'Pending';
      break;
  }

  return { labelColor, labelText };
};

const useGetColumns = ({
  setArchiveConnectionData,
}: {
  setArchiveConnectionData: Dispatch<
    SetStateAction<OutboundRequestsColumnType | null>
  >;
}): GridColDef<OutboundRequestsColumnType>[] => {
  const { enqueueSnackbar } = useSnackbar();

  const { isSharedPage } = useSharedPagesContext();

  const [verifyStartupOutreach] = useVerifyStartupOutreachMutation();

  const [updateStartupOutreachStatus] =
    useUpdateStartupOutreachStatusMutation();

  const columns = [
    { field: 'id' },
    createColumn({
      field: 'status',
      headerName: 'Status',
      sortComparator: (
        v1: StartupConnectionStatus,
        v2: StartupConnectionStatus,
      ) => {
        const order = { initiated: 1, follow_up: 2, accepted: 3 };
        return order[v1] - order[v2];
      },
      renderCell: params => {
        const status = params.value as StartupConnectionStatus;
        const { labelColor, labelText } = getOutreachLabelInfo(status);

        return (
          <Stack alignItems='center' flexDirection='row'>
            <BaseLabel
              sx={({ spacing }) => ({
                paddingX: 1,
                minWidth: spacing(5),
              })}
              color={labelColor}
            >
              {labelText}
            </BaseLabel>
          </Stack>
        );
      },
    }),
    createColumn({
      field: 'name',
      headerName: 'Startup',
      filterable: false,
      valueGetter: (_, row) => row.startup.name,
      minWidth: 255,
      flex: 0.4,
      renderCell: function RenderName(params) {
        const { openStartupSidePanel } = useStartupSidePanel();
        const startup = params.row.startup;

        return (
          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
            width={'100%'}
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              openStartupSidePanel(startup.id, {
                startupListId: undefined,
              })
            }
          >
            <BaseStartupAvatar
              startup={{
                logo_url: startup.logo_url,
                name: startup.name,
                domain: startup.domain,
              }}
              size='medium'
            />
            <Stack>
              <Stack direction='row' alignItems='center' spacing={1}>
                <TypographyWithEllipsis
                  tooltipTitle={
                    <Typography variant='body2'>{startup.name}</Typography>
                  }
                  tooltipVariant='light'
                  variant='body2'
                  sx={({ palette }) => ({
                    color: palette.secondary.main,
                    cursor: 'pointer',
                    maxWidth: 150,
                  })}
                >
                  {startup.name}
                </TypographyWithEllipsis>
              </Stack>
              <BaseCompanyDetails
                company={{
                  employees_count: startup.employees_count,
                  founded_year: startup.founded_year,
                  funding: startup.funding,
                  hq: startup.hq,
                  case_study_count: startup?.referenced_customers?.length,
                }}
              />
            </Stack>
          </Stack>
        );
      },
    }),
    createColumn({
      field: 'created_at',
      headerName: 'Initiated',
      minWidth: 150,
      flex: 0.4,
      valueFormatter: value => {
        const date = value as string;
        return value
          ? formatDistance(new Date(date), new Date(), {
              addSuffix: true,
            })
          : '-';
      },
    }),
    createColumn({
      field: 'contact_details',
      headerName: 'Contacts',
      flex: 0.8,
      renderCell: params => {
        const contactDetails = params.row
          .contact_details as OutboundRequestsContactDetailsType[];
        return (
          <Box>
            {contactDetails.map((contact, index) => (
              <Tooltip title={contact.email} key={contact.id}>
                <Typography component='span' variant='body2'>
                  {contact.full_name}
                  {index < contactDetails.length - 1 ? ', ' : ''}
                </Typography>
              </Tooltip>
            ))}
          </Box>
        );
      },
    }),
    createColumn({
      field: 'project',
      headerName: 'Linked project',
      minWidth: 120,
      flex: 0.5,
      renderCell: ConnectedProjectCell,
    }),
    createColumn({
      field: 'initiated_by',
      headerName: 'Initiated by',
      minWidth: 140,
      flex: 0.5,
      renderCell: params => {
        const initiatedByDetails = params.row.initiated_by;

        if (!params.value) return <Typography variant='body2'>-</Typography>;

        const isOwnedByGD = initiatedByDetails?.person?.email.includes(
          GLASSDOLLAR_EMAIL_DOMAIN,
        );

        return (
          <Stack direction='row' justifyContent='center' alignItems='center'>
            {initiatedByDetails?.person?.id && (
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
              >
                {isOwnedByGD && <GDLogo size={25} />}
                <LinkToPerson
                  fullName={initiatedByDetails.person.full_name || ''}
                  id={initiatedByDetails.person.id}
                  sxProps={{
                    alignText: 'center',
                    alignItems: 'center',
                  }}
                />
              </Stack>
            )}

            {initiatedByDetails.outreach_by_gd && (
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                marginBottom={0.2}
                paddingLeft={0.5}
              >
                <Typography variant='caption'> via </Typography>
                <GDLogo size={30} />
              </Stack>
            )}
          </Stack>
        );
      },
    }),
  ];

  if (!isSharedPage) {
    columns.push(
      createColumn({
        field: 'actions_column',
        headerName: 'Contacts',
        width: 150,
        flex: 0.4,
        renderCell: params => (
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            width='100%'
          >
            {params.row.status !== 'accepted' && (
              <>
                <Tooltip title='Confirm connection'>
                  <IconButton
                    size='small'
                    onClick={e => {
                      e.stopPropagation();
                      try {
                        verifyStartupOutreach({
                          variables: {
                            startup_connection_id: params.row.id,
                          },
                        }).then(() => {
                          enqueueSnackbar(`Connection verified.`, {
                            variant: 'success',
                          }),
                            captureAnalyticsEvent('Connection verified.', {
                              connectionId: params.row.id,
                            });
                        });
                      } catch (error) {
                        captureException(error);
                        enqueueSnackbar(
                          `Error verifying connection. Please try again later.`,
                          {
                            variant: 'error',
                          },
                        );
                      }
                    }}
                  >
                    <VerifyConnectionIcon fontSize='small' />
                  </IconButton>
                </Tooltip>
                {params.row.status !== 'follow_up' && (
                  <Tooltip title='Track follow up'>
                    <IconButton
                      size='small'
                      onClick={e => {
                        e.stopPropagation();
                        try {
                          updateStartupOutreachStatus({
                            variables: {
                              startup_connection_id: params.row.id,
                              status: 'follow_up',
                            },
                          }).then(() => {
                            enqueueSnackbar(`Connection marked to follow up.`, {
                              variant: 'success',
                            }),
                              captureAnalyticsEvent(
                                'Connection marked to follow up.',
                                {
                                  connectionId: params.row.id,
                                },
                              );
                          });
                        } catch (error) {
                          captureException(error);
                          enqueueSnackbar(
                            `Error updating connection. Please try again later.`,
                            {
                              variant: 'error',
                            },
                          );
                        }
                      }}
                    >
                      <FollowUpIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            <Tooltip title='Delete'>
              <IconButton
                size='small'
                onClick={e => {
                  e.stopPropagation();
                  setArchiveConnectionData(
                    params.row as OutboundRequestsColumnType,
                  );
                }}
              >
                <DeleteIcon
                  fontSize='small'
                  sx={({ palette }) => ({
                    color: palette.error.main,
                  })}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      }),
    );
  }
  return columns as GridColDef<OutboundRequestsColumnType>[];
};

const OutboundRequestsTable = () => {
  const { openStartupSidePanel } = useStartupSidePanel();
  const { pinnedRightColumn } = useRightTableActions();
  const { normalizedFilter } = useServerFiltersContext(
    'outboundRequestsFilters',
  );

  const { data } = useGetStartupOutboundRequestsQuery({
    variables: { filter: normalizedFilter },
    fetchPolicy: 'cache-and-network',
  });

  const [archiveConnectionData, setArchiveConnectionData] =
    useState<OutboundRequestsColumnType | null>(null);

  const columns = useGetColumns({ setArchiveConnectionData });

  const rows = (data?.startup_connections || []).map(connection => ({
    id: connection.id,
    status: connection.status,
    created_at: connection.created_at,
    startup: connection.startup,
    contact_details: connection.contacts.map(contact => {
      return {
        id: contact.organization_startup_contact.id,
        email: contact.organization_startup_contact.email,
        full_name: contact.organization_startup_contact.full_name,
      };
    }),
    initiated_by: {
      outreach_by_gd: connection.outreach_by_gd,
      person: connection.created_by?.person || undefined,
    },
    project: connection.project,
  }));

  const [sortingModel, setSortingModel] = useState<GridSortModel>(
    DEFAULT_SORTING_MODEL,
  );

  return (
    <Card>
      <TypeSafeStyledDataGrid<OutboundRequestsColumnType>
        rows={rows}
        loading={data === undefined}
        sortModel={sortingModel}
        onSortModelChange={setSortingModel}
        columns={columns}
        initialState={{
          pinnedColumns: { right: pinnedRightColumn },
          columns: {
            columnVisibilityModel: { id: false },
          },
          pagination: { paginationModel: { pageSize: 30 } },
        }}
        pageSizeOptions={[30]}
        onRowClick={value => {
          openStartupSidePanel(value.row.startup.id, {
            startupListId: undefined,
          });
        }}
        slots={{
          noRowsOverlay: () => (
            <Box sx={{ flexGrow: 1, height: 320 }}>
              <EmptyContent
                title={
                  'You have no connections yet. Visit a startup profile to connect.'
                }
                sx={{ flexGrow: 1, height: 'auto' }}
              />
            </Box>
          ),
          toolbar: CustomToolbar,
        }}
        disableRowSelectionOnClick
        pagination
        isRowClickable={false}
      />
      <ArchiveRequestModal
        onClose={() => setArchiveConnectionData(null)}
        connectionId={archiveConnectionData?.id || null}
        startupName={archiveConnectionData?.startup.name || null}
      />
    </Card>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer
      sx={{
        justifyContent: 'flex-end',
      }}
    >
      <BaseServerFilterMenu scope='outboundRequestsFilters'>
        <InitiatedByFilter />
      </BaseServerFilterMenu>
    </GridToolbarContainer>
  );
}

export { OutboundRequestsTable };
