import { CSSObject, styled, SxProps } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';

const CommonLabelStyles = (theme: Theme): CSSObject => ({
  display: 'inline-block',
  paddingLeft: 5,
  paddingRight: 5,
  paddingTop: 4,
  paddingBottom: 4,
  borderRadius: 11,
  textAlign: 'center',
  fontSize: '13px',
  letterSpacing: '.3px',
  color: theme.palette.common.white,
});

export const GreenOutlinedLabelStyle = styled('span')(({ theme }) => ({
  ...CommonLabelStyles(theme),
  backgroundColor: 'rgba(84, 214, 44, 0.16)',
  color: '#00AB55',
  lineHeight: 1,
  paddingLeft: 8,
  paddingRight: 8,
}));

export const ConnectedStartupLabel = ({ sx }: { sx?: SxProps }) => {
  return <GreenOutlinedLabelStyle sx={sx}>Connected</GreenOutlinedLabelStyle>;
};
