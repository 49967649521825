import { EnumTableProjectStagesEnum } from 'apollo/generated/sdkInnovationManager';
import { union, uniq } from 'lodash';
import { ArrayElementType } from '../@types/utils';

const INBOX_STAGES = ['pending_request', 'discarded_request'] as const;
export const LIST_INITIAL_STAGE = 'sourcing' as const;

export const LEAD_STAGES = [
  'scoping' as const,
  'sourcing' as const,
  'sourcing_completed' as const,
];

export const POC_STAGES = [
  'assessment' as const,
  'purchasing' as const,
  'testing' as const,
  'test_fail' as const,
  'test_success' as const,
];

export const POC_STAGES_FOR_KANBAN = [
  ...POC_STAGES,
  'discarded' as const,
  'on_hold' as const,
];

const ADOPTION_STAGES = [
  'adoption' as const,
  'adopted' as const,
  'not_adopted' as const,
  'scaling' as const,
  'scaling_completed' as const,
];

export const ADOPTION_STAGES_FOR_KANBAN = [...ADOPTION_STAGES];

export const E2E_STAGES = [
  ...LEAD_STAGES,
  ...POC_STAGES,
  'discarded' as const,
  'on_hold' as const,
  ...ADOPTION_STAGES,
];

export const VISIBLE_PROJECT_STAGES = union([
  ...POC_STAGES,
  'discarded' as const,
  'on_hold' as const,
  ...ADOPTION_STAGES,
]);

export type VisibleProjectStages = ArrayElementType<
  typeof VISIBLE_PROJECT_STAGES
>;

export const ALL_PROJECT_STAGES = uniq([...INBOX_STAGES, ...E2E_STAGES]);

export type ProjectInboxStages = ArrayElementType<typeof INBOX_STAGES>;
export type E2EStages = ArrayElementType<typeof E2E_STAGES>;

export const indexOfStage = (stage: EnumTableProjectStagesEnum) =>
  (ALL_PROJECT_STAGES as string[]).indexOf(stage);

export const DECORATED_PROJECT_STAGE_MAPPING = {
  scoping: 'Scoping',
  sourcing: 'In Sourcing',
  sourcing_completed: 'Sourcing Completed',
  assessment: 'Assessment',
  discarded: 'Archived',
};

export const DECORATED_STAGE_MAPPING: {
  [key in EnumTableProjectStagesEnum]: string;
} = {
  pending_request: 'Request',
  discarded_request: 'Discarded Request',
  scoping: 'Scoping',
  sourcing: 'In Sourcing',
  // TODO: deprecate the below stage as completed sourcings are presently being tracked
  // from the orders table
  sourcing_completed: 'Sourcing Completed',
  assessment: 'Assessment',
  purchasing: 'Purchasing',
  pilot: 'Pilot',
  // --------
  pilot_completed: 'Pilot Completed',
  test_fail: 'Test Fail',
  test_success: 'Test Success',
  testing: 'Testing',
  adoption: 'Adoption',
  adopted: 'Adopted',
  not_adopted: 'Not Adopted',
  scaling: 'Scaling',
  scaling_completed: 'Scaling Completed',
  on_hold: 'On Hold',
  discarded: 'Archived',
  discovery_item: 'Discovery Item',
};

export const PROJECTS_STAGE_MAPPING = {
  scoping: 'Scoping',
  sourcing: 'Sourcing',
  assessment: 'Assessment',
  purchasing: 'Purchasing',
  test_success: 'Test Success',
  test_fail: 'Test Fail',
  testing: 'Testing',
  adoption: 'Adoption',
  adopted: 'Adopted',
  not_adopted: 'Not Adopted/Scaled',
  scaling: 'Scaling',
  scaling_completed: 'Scaling Completed',
  on_hold: 'On Hold',
  discarded: 'Archived',
};

export const DECORATED_SOURCING_ONLY_STAGE_MAPPING = {
  scoping: 'Scoping',
  sourcing: 'Sourcing',
  // TODO: deprecate the below stage as completed sourcings are presently being tracked
  // from the orders table
  sourcing_completed: 'Sourcing Completed',
  assessment: 'Assessment',
  discarded: 'Archived',
  // Mapping for requests only
  pending_request: '-',
  discarded_request: '-',
  // Not available for scouting-only
  purchasing: '-',
  pilot: '-',
  pilot_completed: '-',
  adoption: '-',
  adopted: '-',
  not_adopted: '-',
  scaling: '-',
  scaling_completed: '-',
  on_hold: '-',
  discovery_item: '-',
};

export const canMoveToStage = (
  _fromStage: EnumTableProjectStagesEnum,
  toStage: EnumTableProjectStagesEnum,
  selectedStartup: { id: number } | null | undefined,
): [boolean, string] => {
  if (toStage === 'discarded' || toStage === 'on_hold') {
    return [true, ''];
  }

  if (indexOfStage(toStage) > indexOfStage('assessment') && !selectedStartup)
    return [
      false,
      'Select a startup for PoC before moving the project forward.',
    ];

  return [true, ''];
};

export const SIEMENS_PURCHASING = 'PoC Planning';
export const SIEMENS_TESTING = 'PoC Execution';
export const SIEMENS_SUBDOMAIN = 'siemens';

export const mapStageNameForOrganization = ({
  name,
  organizationSubdomain,
}: {
  name: string;
  organizationSubdomain: string;
}) => {
  if (organizationSubdomain === SIEMENS_SUBDOMAIN) {
    switch (name) {
      case 'Purchasing': {
        return SIEMENS_PURCHASING;
      }
      case 'Testing': {
        return SIEMENS_TESTING;
      }

      default:
        return name;
    }
  }

  return name;
};

export const getExtendedStageMapping = ({
  organizationSubdomain,
}: {
  organizationSubdomain: string;
}): typeof DECORATED_STAGE_MAPPING => {
  if (organizationSubdomain === SIEMENS_SUBDOMAIN) {
    return {
      ...DECORATED_STAGE_MAPPING,
      purchasing: SIEMENS_PURCHASING,
      testing: SIEMENS_TESTING,
    };
  }

  return DECORATED_STAGE_MAPPING;
};

export const IMPLEMENTATION_STAGES = ADOPTION_STAGES_FOR_KANBAN.filter(
  s => s !== 'not_adopted',
);
