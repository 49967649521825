import { groupBy } from 'lodash';
import { MessageRecommendedStartup } from './ConversationMessage';
import {
  Grid,
  Grid2,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { BaseStartupCard } from 'components/base/BaseStartupCard';
import { BaseAddStartupToListMenu } from 'components/base/BaseAddStartupToListMenu';
import { LowPriority, Send } from '@mui/icons-material';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import ShareStartupModal from './ShareStartupModal';
import { useState } from 'react';
import useAuth from 'hooks/useAuth';

export const RecommendedStartups = ({
  startups,
  submitMessage,
  threadId,
}: {
  startups: MessageRecommendedStartup[];
  submitMessage: (message: string) => void;
  threadId: number;
}) => {
  const startupsWithCategory = groupBy(startups, 'category');

  const showMultipleCategories =
    Object.keys(startupsWithCategory).length > 1 &&
    Object.keys(startupsWithCategory).some(
      category => startupsWithCategory[category].length > 1,
    );

  return showMultipleCategories ? (
    <Stack spacing={2}>
      {Object.keys(startupsWithCategory).map(category => (
        <CategorySection
          key={category}
          threadId={threadId}
          categoryName={category}
          startups={startupsWithCategory[category]}
          submitMessage={submitMessage}
        />
      ))}
    </Stack>
  ) : (
    <Grid2 container spacing={2} sx={{ marginTop: 0.2 }}>
      {startups.map(startup => (
        <StartupCard
          threadId={threadId}
          startup={startup}
          key={startup.id}
          submitMessage={submitMessage}
        />
      ))}
    </Grid2>
  );
};

const CategorySection = ({
  categoryName,
  startups,
  submitMessage,
  threadId,
}: {
  categoryName: string;
  startups: MessageRecommendedStartup[];
  submitMessage: (message: string) => void;
  threadId: number;
}) => {
  return (
    <Stack spacing={1} paddingTop={2}>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h5'>
          {categoryName} ({startups.length})
        </Typography>
        <BaseAddStartupToListMenu
          shouldAddMultiple
          selectedStartups={startups}
          analyticsSource='ASSISTANT'
        />
      </Stack>
      <Grid container spacing={2}>
        {startups.map(startup => (
          <StartupCard
            threadId={threadId}
            startup={startup}
            key={startup.id}
            submitMessage={submitMessage}
          />
        ))}
      </Grid>
    </Stack>
  );
};

const StartupCard = ({
  startup,
  submitMessage,
  threadId,
}: {
  startup: MessageRecommendedStartup;
  submitMessage: (message: string) => void;
  threadId?: number;
}) => {
  const { user } = useAuth();
  const { spacing } = useTheme();
  const [shareModalOpen, setShareModalOpen] = useState(false);

  return (
    <>
      <BaseStartupCard
        key={startup.id}
        avatarSize='small'
        startup={{
          ...startup.startup,
          short_description:
            startup.highlight || startup.startup.short_description,
        }}
        hoverActions={
          <Stack direction='row' spacing={1} alignItems='center'>
            <BaseAddStartupToListMenu
              buttonSx={{ width: spacing(5), height: spacing(5) }}
              selectedStartups={[startup.startup]}
              analyticsSource='ASSISTANT'
            />
            <Tooltip title='Search similar startups'>
              <span>
                <IconButton
                  color='primary'
                  onClick={() =>
                    submitMessage(
                      `Tell me more about ${startup.startup.name} and suggest me 3 similar startups.`,
                    )
                  }
                >
                  <LowPriority fontSize='small' />
                </IconButton>
              </span>
            </Tooltip>
            {threadId && (
              <Tooltip title='Send startup to a stakeholder'>
                <span>
                  <IconButton
                    color='primary'
                    onClick={() => {
                      captureAnalyticsEvent('Send Startup > Open Modal', {
                        startupId: startup.id,
                        source: 'ASSISTANT',
                        senderEmail: user.email,
                      });
                      setShareModalOpen(true);
                    }}
                  >
                    <Send fontSize='small' />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Stack>
        }
        trackFn={() =>
          captureAnalyticsEvent('Leads assistant startup clicked', {
            startupId: startup.startup.id,
          })
        }
      />
      {shareModalOpen && threadId && (
        <ShareStartupModal
          threadId={threadId}
          onClose={() => setShareModalOpen(false)}
          startup={startup}
        />
      )}
    </>
  );
};
