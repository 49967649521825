import BaseLabel from 'components/base/BaseLabel';

export const ListsLabel = ({ lists }: { lists: string[] }) => {
  if (!lists?.length) return <span></span>;
  return (
    <div
      style={{
        whiteSpace: 'initial',
        lineHeight: 'initial',
      }}
    >
      {lists && lists?.length > 1 ? (
        <>
          <BaseLabel>{lists[0]}</BaseLabel>
          <BaseLabel>{`+${lists.length - 1}`}</BaseLabel>
        </>
      ) : (
        <BaseLabel>{lists && lists[0]}</BaseLabel>
      )}
    </div>
  );
};
