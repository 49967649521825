import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { SearchSubtitle } from './shared/SearchSubtitle';
import {
  BaseStartupCard,
  StartupForCard,
} from 'components/base/BaseStartupCard';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import {
  NameSearchDocument,
  NameSearchQuery,
  useNameSearchLazyQuery,
  useWebSearchMutation,
  WebSearchMutation,
} from 'apollo/generated/sdkShared';
import { useCallback, useEffect } from 'react';
import { DocumentNode } from '@apollo/client';

export type NameSearchResultType = {
  supplier: StartupForCard;
};

export const SearchNameResults = ({ query }: { query: string }) => {
  const { handleNameSearch, nameSearchData, nameSearchLoading, searchCalled } =
    useNameSearch();

  const { handleWebSearch, webSearchData, webSearchLoading } = useWebSearch();

  useEffect(() => {
    handleNameSearch(query);
    handleWebSearch(query);
  }, [query, handleNameSearch, handleWebSearch]);

  return (
    <>
      <NameSearchTitle
        nameSearchLoading={nameSearchLoading}
        webSearchLoading={webSearchLoading}
        nameSearchData={nameSearchData}
        webSearchData={webSearchData}
        searchCalled={searchCalled}
      />
      <Grid container rowSpacing={2} columnSpacing={2} sx={{ marginTop: 0.5 }}>
        {nameSearchData?.name_search
          ?.filter((result): result is NameSearchResultType => result !== null)
          .map((result, i) => (
            <NameSearchResult
              key={`name_${result.supplier.id}`}
              enrichment={{
                autoEnrich: true,
                queryDocument: NameSearchDocument,
                enrichmentKey: `name_search_${result.supplier.id}`,
              }}
              supplier={result.supplier}
              trackFn={() =>
                captureAnalyticsEvent('Search name result clicked', {
                  searchQuery: query,
                  clickedResultDomain: result.supplier.domain,
                  clickedResultIndex: i,
                  totalNumberOfResults: nameSearchData?.name_search.length,
                })
              }
            />
          ))}
        {webSearchData?.web_search
          ?.filter((result): result is NameSearchResultType => result !== null)
          ?.filter(
            result =>
              !nameSearchData?.name_search
                .map(r => r?.supplier?.domain)
                .includes(result.supplier.domain),
          )
          .map((result, i) => (
            <NameSearchResult
              key={`web_${result.supplier.id}`}
              supplier={result.supplier}
              trackFn={() =>
                captureAnalyticsEvent('Web search result clicked', {
                  searchQuery: query,
                  clickedResultDomain: result.supplier.domain,
                  clickedResultIndex: i,
                  totalNumberOfResults: webSearchData?.web_search.length,
                })
              }
            />
          ))}
      </Grid>
    </>
  );
};

const NameSearchResult = ({
  supplier,
  trackFn,
  enrichment,
}: {
  supplier: StartupForCard;
  trackFn: () => void;
  enrichment?: {
    autoEnrich: boolean;
    queryDocument: DocumentNode;
    enrichmentKey?: string;
  };
}) => {
  return (
    <BaseStartupCard
      enrichment={enrichment}
      trackFn={trackFn}
      startup={supplier}
      avatarSize='medium'
      gridProps={{ xs: 12, sm: 6, md: 4, lg: 3 }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '& .MuiCard-root': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    />
  );
};

const NameSearchTitle = ({
  nameSearchLoading,
  webSearchLoading,
  nameSearchData,
  webSearchData,
  searchCalled,
}: {
  nameSearchLoading: boolean;
  webSearchLoading: boolean;
  nameSearchData: NameSearchQuery | undefined;
  webSearchData: WebSearchMutation | undefined | null;
  searchCalled: boolean;
}) => {
  const isLoading = nameSearchLoading || webSearchLoading;
  const isEmpty =
    searchCalled &&
    !nameSearchData?.name_search.length &&
    !webSearchData?.web_search.length;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <SearchSubtitle>Startups by name</SearchSubtitle>
      {isLoading ? (
        <CircularProgress size={20} sx={{ mt: 1 }} />
      ) : (
        isEmpty && (
          <Typography variant='body2' sx={{ mt: 1.3, color: 'text.secondary' }}>
            No results found
          </Typography>
        )
      )}
    </Box>
  );
};

const useNameSearch = () => {
  const [nameSearch, { data, loading, called }] = useNameSearchLazyQuery();
  const handleNameSearch = useCallback(
    async (query: string) => {
      if (!query) return;

      nameSearch({ variables: { query } });
    },
    [nameSearch],
  );

  return {
    searchCalled: called,
    nameSearchData: data,
    handleNameSearch,
    nameSearchLoading: loading,
  };
};

const useWebSearch = () => {
  const [webSearch, { data, loading }] = useWebSearchMutation();

  const handleWebSearch = useCallback(
    async (query: string) => {
      if (!query) return;

      webSearch({ variables: { query } });
    },
    [webSearch],
  );

  return {
    webSearchData: data,
    handleWebSearch,
    webSearchLoading: loading,
  };
};
