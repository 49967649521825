// Keep the imports relative, otherwise web worker build will fail
// ! Cannot be a hook, because it's used in web workers
import { EnumTableProjectStakeholderRolesEnum } from '../apollo/generated/sdkInnovationManager';
import { SIEMENS_SUBDOMAIN } from './projectStageEnum';

export type ExtendedProjectRoles =
  | EnumTableProjectStakeholderRolesEnum
  | 'primary';

export type PROJECT_ROLES = {
  project_sponsor: 'Project Sponsor';
  project_team: 'Other Stakeholders (Business Unit)';
  primary: 'Primary Contact (Startup)';
  other: 'Other Stakeholders (Startup)';
};

const PROJECT_ROLES_MAP: Record<ExtendedProjectRoles, string> = {
  // Business Unit
  project_leader: 'Pain Point Owner',
  project_sponsor: 'Project Sponsor',
  project_team: 'Other Stakeholders (Business Unit)',
  // Startup
  primary: 'Primary Contact (Startup)',
  other: 'Other Stakeholders (Startup)',
  adoption_committee: 'Adoption Committee',
  adoption_owner: 'Adoption Owner',
  key_stakeholders_gatekeeper: 'Key Stakeholders/Gatekeepers',
  project_owner: 'Project Owner',
  technical_expert: 'Technical Expert',
};

export const ALLOWED_PROJECT_ROLES: Partial<ExtendedProjectRoles>[] = [
  'project_leader',
  'project_sponsor',
  'project_team',
  'technical_expert',
];

export const getStakeholderRole = ({
  subdomain,
  override,
}: {
  // ! Cannot be taken from context, because it's used in web workers
  // ! Once you import useCurrentOrganizationFromContext, the build will start failing
  subdomain: string;
  override?: Partial<Record<keyof typeof PROJECT_ROLES_MAP, string>>;
}) => {
  if (subdomain === SIEMENS_SUBDOMAIN) {
    return {
      PROJECT_ROLES_MAP: {
        ...PROJECT_ROLES_MAP,
        project_leader: 'Challenge Owner',
        project_sponsor: 'Business Owner',
        others: 'Others',
        ...override,
      },
    };
  }

  return {
    PROJECT_ROLES_MAP: {
      ...PROJECT_ROLES_MAP,
      others: 'Others',
      ...override,
    },
  };
};
