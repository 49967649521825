import React, { useEffect, useCallback } from 'react';
import {
  Button,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  useFindSimilarStartupsQuery,
  useAsyncFindSimilarStartupsMutation,
} from 'apollo/generated/sdkShared';
import { StartupForSidePanel } from './StartupInfoSidePanel';
import useLocalStorage from 'hooks/useLocalStorage';
import { isOrganizationPortalURL } from 'utils/url';
import useAuth from 'hooks/useAuth';
import useSearchSimilarActions from 'hooks/useSearchSimilarActions';
import { captureException } from '@sentry/react';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { BaseStartupCard } from 'components/base/BaseStartupCard';
interface SimilarStartupsProps {
  startup: StartupForSidePanel;
}

interface SimilarStartupsListProps {
  currentStartup: StartupForSidePanel;
  asyncId: string;
  setAsyncIdSessionStorage: (id: string | undefined) => void;
}

const SimilarStartups: React.FC<SimilarStartupsProps> = ({ startup }) => {
  return (
    <Stack>
      <Typography
        sx={{
          color: 'grey.700',
        }}
        fontWeight={700}
      >
        Similar startups
      </Typography>

      <Grid container spacing={2} sx={{ marginTop: 0.2 }}>
        <>
          {startup.similar_startups.length > 0 ? (
            <SimilarStartupsFromDB startup={startup} />
          ) : (
            <RequestAsyncSimilarStartups startup={startup} />
          )}
          <ShowMoreButton currentStartup={startup} />
        </>
      </Grid>
    </Stack>
  );
};

const ShowMoreButton: React.FC<{
  currentStartup: StartupForSidePanel;
}> = ({ currentStartup }) => {
  const isValidOrganizationPortalURL = isOrganizationPortalURL();
  const { user } = useAuth();
  const { onSearchSimilarClickFromStartupProfile } = useSearchSimilarActions();

  const isOrganizationPortal =
    isValidOrganizationPortalURL || user.type === 'bu_member';

  return (
    <Grid item xs={12}>
      {isOrganizationPortal ? (
        <Button
          variant='text'
          sx={{
            width: '100%',
          }}
          onClick={() =>
            onSearchSimilarClickFromStartupProfile(
              currentStartup.domain,
              currentStartup.id,
            )
          }
        >
          Search similar
        </Button>
      ) : (
        <Tooltip title='Search similar'>
          <Button
            color='primary'
            sx={{
              width: '100%',
            }}
            onClick={() =>
              onSearchSimilarClickFromStartupProfile(
                currentStartup.domain,
                currentStartup.id,
              )
            }
          >
            <Typography sx={{ fontWeight: 600 }}>Search similar</Typography>
          </Button>
        </Tooltip>
      )}
    </Grid>
  );
};

const SimilarStartupsFromDB: React.FC<{
  startup: StartupForSidePanel;
}> = ({ startup }) => {
  return (
    <>
      {startup.similar_startups.map(similarStartup => (
        <BaseStartupCard
          key={similarStartup.similar_startup.id}
          startup={{
            ...similarStartup.similar_startup,
            // TODO(@zedevs) Pass labels
            signal_label: undefined,
            funnel_label: undefined,
          }}
          trackFn={() =>
            captureAnalyticsEvent('Similar startup clicked', {
              startupId: startup!.id,
              similarStartupId: similarStartup.similar_startup.id,
            })
          }
          avatarSize='medium'
          afterOpen={() =>
            captureAnalyticsEvent('Similar startup clicked', {
              startupId: startup!.id,
              similarStartupId: similarStartup.similar_startup.id,
            })
          }
        />
      ))}
    </>
  );
};

const RequestAsyncSimilarStartups: React.FC<SimilarStartupsProps> = ({
  startup,
}) => {
  const [asyncFindSimilarStartups] = useAsyncFindSimilarStartupsMutation();
  const [asyncIdSessionStorage, setAsyncIdSessionStorage] = useLocalStorage<
    string | undefined
  >(
    `async-similar-startups-${startup.domain}`,
    undefined,
    undefined,
    undefined,
    true,
  );

  const handleAsyncFindSimilarStartups = useCallback(async () => {
    //? Callback probably useful in this case, discuss with Fipo or Alex
    const { data: asyncData } = await asyncFindSimilarStartups({
      variables: { startupDomain: startup.domain },
    });
    setAsyncIdSessionStorage(asyncData?.find_similar_startups);
  }, [asyncFindSimilarStartups, startup.domain, setAsyncIdSessionStorage]);

  useEffect(() => {
    if (asyncIdSessionStorage) return;

    handleAsyncFindSimilarStartups();
  }, [handleAsyncFindSimilarStartups, asyncIdSessionStorage]);

  return (
    <>
      {asyncIdSessionStorage && (
        <PollSimilarStartupsList
          asyncId={asyncIdSessionStorage}
          setAsyncIdSessionStorage={setAsyncIdSessionStorage}
          currentStartup={startup}
        />
      )}
    </>
  );
};

const PollSimilarStartupsList: React.FC<SimilarStartupsListProps> = ({
  asyncId,
  currentStartup,
}) => {
  const { data: similarStartupsData, stopPolling } =
    useFindSimilarStartupsQuery({
      variables: { id: asyncId },
      pollInterval: 2000,
    });

  useEffect(() => {
    const timeout = setTimeout(() => {
      return <ErrorState />;
    }, 60000);

    return () => {
      stopPolling();

      clearTimeout(timeout);
    };
  }, [stopPolling]);

  if (similarStartupsData?.find_similar_startups?.errors) {
    stopPolling();
    captureException(similarStartupsData.find_similar_startups.errors);
    return <ErrorState />;
  }

  if (!similarStartupsData?.find_similar_startups?.output?.length) {
    return <LoadingState />;
  }

  stopPolling();

  return (
    <>
      {similarStartupsData?.find_similar_startups?.output?.map(startup => (
        <BaseStartupCard
          key={startup.id}
          startup={{
            ...startup,
            // TODO(@zedevs) Pass labels
            signal_label: undefined,
            funnel_label: undefined,
          }}
          avatarSize='small'
          afterOpen={() =>
            captureAnalyticsEvent('Similar startup clicked', {
              startupId: currentStartup!.id,
              similarStartupId: startup!.id,
            })
          }
          trackFn={() =>
            captureAnalyticsEvent('Similar startup clicked', {
              startupId: startup!.id,
              similarStartupId: startup!.id,
            })
          }
        />
      ))}
    </>
  );
};

const LoadingState: React.FC = () => {
  return (
    <>
      <Grid item xs={6}>
        <Skeleton variant='rectangular' height={100} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant='rectangular' height={100} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant='rectangular' height={100} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant='rectangular' height={100} />
      </Grid>
    </>
  );
};

const ErrorState: React.FC = () => {
  return (
    <Grid item xs={12}>
      <Typography>
        We couldn&apos;t find any similar startups at this time. Please, try
        again later.
      </Typography>
    </Grid>
  );
};

export default SimilarStartups;
