import { Avatar, Link, Stack, Typography } from '@mui/material';

import { OpenInNew } from '@mui/icons-material';
import { useFormikContext } from 'formik';
import { getNameInitials } from 'utils/general';
import { StartupFormState } from './useStartupDetailsFormik';
import { captureAnalyticsEvent } from 'plugins/Analytics';

const FIELD_NAME = 'referenced_customers';

export const ReferencedCustomersField = ({ label }: { label: string }) => {
  const { values } = useFormikContext<StartupFormState>();

  const fieldValue = values[FIELD_NAME];

  if (!fieldValue.length) {
    return null;
  }

  return (
    <Stack gap={1}>
      <Typography
        sx={{
          color: 'grey.700',
        }}
        fontWeight={700}
      >
        {label}
      </Typography>
      <Stack spacing={2} direction='row' useFlexGap flexWrap='wrap'>
        {/* DEPRECATE parent_customer_entry */}
        {fieldValue.map(c => {
          const customer = {
            name: c.name,
            domain: c.domain,
            logo_url: c.logo_url,
          };

          return (
            <Stack direction='row' key={customer.domain} spacing={1}>
              <Avatar
                alt={customer.domain || ''}
                src={customer?.logo_url || ''}
              >
                {getNameInitials(customer.domain)}
              </Avatar>

              <Stack justifyContent='center'>
                <Typography variant='body2'>
                  {customer.name || customer.domain}
                </Typography>

                {c.case_study_url && (
                  <Link
                    href={
                      c.case_study_url.startsWith('http')
                        ? c.case_study_url
                        : `https://${c.case_study_url}` || ''
                    }
                    onClick={() => {
                      captureAnalyticsEvent('View Startup > Open case study', {
                        startupId: c.id,
                        caseStudyURL: c.case_study_url,
                      });
                    }}
                    sx={{ display: 'inherit' }}
                    target='_blank'
                    rel='noreferrer'
                    color='secondary'
                    underline='none'
                    variant='caption'
                  >
                    <OpenInNew
                      sx={{
                        fontSize: 'inherit',
                        marginTop: 0.5,
                        marginRight: 0.5,
                      }}
                    />
                    Case Study{' '}
                  </Link>
                )}
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
