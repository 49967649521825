import { AddOutlined, Clear, Email } from '@mui/icons-material';
import {
  Button,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import BaseInitialsAvatar from 'components/base/BaseInitialsAvatar';
import { BaseShowMoreOrLess } from 'components/base/BaseShowMoreLess';
import EmailAllCopy from 'components/base/customIcons/EmailAllCopy';
import { DetailsSectionCard } from 'components/DetailsSectionCard';
import LinkToPerson from 'components/engagement/people/LinkToPerson';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { CustomTypography } from 'pages/dashboard/scoping/ProjectSummaryStartupsAvatars';
import { useMemo, useState } from 'react';
import { getStakeholderRole } from 'utils/getStakeholderRole';
import {
  ChallengeConnectedProjectType,
  ChallengeStakeholderType,
  ChallengeType,
} from '../types';
import { AddChallengeStakeholderModal } from './AddChallengeStakeholderModal';
import { RemoveChallengeStakeholderModal } from './RemoveChallengeStakeholderModal';

const StakeholderItem = ({
  challengeStakeholder,
  projectName,
  isStakeholderRemovable,
  setChallengeStakeholderToBeRemoved,
}: {
  challengeStakeholder: ChallengeStakeholderType;
  projectName?: string;
  isStakeholderRemovable: boolean;
  setChallengeStakeholderToBeRemoved: (
    stakeholder: ChallengeStakeholderType,
  ) => void;
}) => {
  const { subdomain } = useCurrentOrganizationFromContext();
  const { PROJECT_ROLES_MAP } = getStakeholderRole({
    subdomain,
    override: {
      project_team: 'Innovation Team',
    },
  });
  const { palette, spacing } = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  const stakeholder = challengeStakeholder.stakeholder;

  const stakeholderNameText = `${stakeholder?.full_name}${
    stakeholder.organization_department?.name
      ? ` (${stakeholder.organization_department.name})`
      : ''
  }`;
  const roleLabel = useMemo(
    () => PROJECT_ROLES_MAP[challengeStakeholder.role] || 'Other Contact',
    [PROJECT_ROLES_MAP, challengeStakeholder.role],
  );

  return (
    <>
      {projectName && (
        <Stack spacing={1}>
          <CustomTypography
            textAlign='left'
            color='#939EAA'
            sx={{
              fontSize: 12,
            }}
          >
            {projectName}
          </CustomTypography>
          <Divider />
        </Stack>
      )}
      <Stack
        direction='row'
        gap={1}
        alignItems='center'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Tooltip title={stakeholderNameText}>
          <Stack
            direction='row'
            flexWrap='nowrap'
            flexGrow={1}
            minWidth={0}
            alignItems='center'
          >
            <BaseInitialsAvatar
              full_name={stakeholderNameText}
              sx={{
                width: ({ spacing }) => spacing(3),
                height: ({ spacing }) => spacing(3),
                fontSize: 10,
              }}
            />
            <Stack minWidth={0}>
              <LinkToPerson
                fullName={stakeholderNameText}
                id={stakeholder.id}
              />
              <Typography sx={{ fontSize: 12, color: palette.grey[500] }}>
                {roleLabel}
              </Typography>
            </Stack>
          </Stack>
        </Tooltip>
        <Stack direction='row' marginLeft='auto' gap={1}>
          {isStakeholderRemovable && (
            <Tooltip title='Remove'>
              <IconButton
                size='small'
                sx={{
                  marginY: 'auto',
                  marginLeft: 'auto',
                  display: 'inline-flex',
                  width: spacing(3),
                  height: spacing(3),
                  visibility: isHovered ? 'visible' : 'hidden',
                }}
                onClick={() =>
                  setChallengeStakeholderToBeRemoved(challengeStakeholder)
                }
              >
                <Clear
                  fontSize='small'
                  sx={{ width: spacing(2.5), height: spacing(2.5) }}
                />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={`Send email to ${stakeholder.email}`}>
            <IconButton
              onClick={() =>
                window.open(`mailto:${stakeholder.email}`, '_blank')
              }
              size='small'
              sx={{ marginLeft: 'auto' }}
            >
              <Email fontSize='small' sx={{ color: palette.grey[500] }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </>
  );
};

export const ChallengeStakeholdersSection = ({
  challenge,
  connectedLeads,
  connectedProjects,
  isReadonly,
}: {
  challenge: ChallengeType;
  connectedLeads: ChallengeConnectedProjectType[];
  connectedProjects: ChallengeConnectedProjectType[];
  isReadonly?: boolean;
}) => {
  const [
    addChallengeStakeholderModalOpened,
    setAddChallengeStakeholderModalOpened,
  ] = useState(false);

  const [stakeholderToBeRemoved, setStakeholderToBeRemoved] =
    useState<ChallengeStakeholderType | null>(null);

  const challengeStakeholders = challenge.challenge_stakeholders;

  const connectedProjectStakeholders = connectedProjects.flatMap(
    connectedProject => connectedProject.project_stakeholders,
  );

  const connectedLeadStakeholders = connectedLeads.flatMap(
    connectedLead => connectedLead.project_stakeholders,
  );

  const allStakeholders = useMemo(
    () => [
      ...challengeStakeholders,
      ...connectedLeadStakeholders,
      ...connectedProjectStakeholders,
    ],
    [
      challengeStakeholders,
      connectedLeadStakeholders,
      connectedProjectStakeholders,
    ],
  );

  const allStakeholderEmails = (
    allStakeholders.filter(ps =>
      ['project_team', 'project_sponsor', 'project_leader'].includes(ps.role),
    ) || []
  )
    ?.map(s => s.stakeholder.email)
    .join(';');

  return (
    <Stack gap={2}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <CustomTypography>Stakeholders</CustomTypography>
        <Stack alignItems='center' direction='row'>
          {!isReadonly && (
            <Button
              variant='text'
              size='small'
              sx={{ whiteSpace: 'nowrap', marginTop: 0.5 }}
              onClick={() => setAddChallengeStakeholderModalOpened(true)}
              startIcon={<AddOutlined />}
            >
              Add
            </Button>
          )}
          {allStakeholderEmails && (
            <Tooltip title='Email all stakeholders'>
              <IconButton
                onClick={() =>
                  window.open(`mailto:${allStakeholderEmails}`, '_blank')
                }
                sx={{
                  padding: 0.7,
                }}
              >
                <EmailAllCopy fontSize='small' />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Stack>
      <Divider />
      <BaseShowMoreOrLess
        alwaysExpanded={allStakeholders.length < 10}
        collapsedSize={'65vh'}
        allowEdit={false}
        content={
          <DetailsSectionCard>
            <Stack spacing={3}>
              {challengeStakeholders.map(challengeStakeholder => (
                <StakeholderItem
                  key={challengeStakeholder.id}
                  challengeStakeholder={challengeStakeholder}
                  isStakeholderRemovable={!isReadonly}
                  setChallengeStakeholderToBeRemoved={setStakeholderToBeRemoved}
                />
              ))}
              {connectedLeadStakeholders.map((leadStakeholder, index) => (
                <StakeholderItem
                  key={leadStakeholder.id}
                  challengeStakeholder={leadStakeholder}
                  projectName={connectedLeads[index]?.title || ''}
                  isStakeholderRemovable={false}
                  setChallengeStakeholderToBeRemoved={setStakeholderToBeRemoved}
                />
              ))}
              {connectedProjectStakeholders.map((projectStakeholder, index) => (
                <StakeholderItem
                  key={projectStakeholder.id}
                  challengeStakeholder={projectStakeholder}
                  projectName={connectedProjects[index]?.title || ''}
                  isStakeholderRemovable={false}
                  setChallengeStakeholderToBeRemoved={setStakeholderToBeRemoved}
                />
              ))}
            </Stack>
          </DetailsSectionCard>
        }
      />

      <AddChallengeStakeholderModal
        challengeId={challenge.id}
        challengeStakeholderIds={challengeStakeholders.map(
          cs => cs.stakeholder.id,
        )}
        open={addChallengeStakeholderModalOpened}
        onHide={() => setAddChallengeStakeholderModalOpened(false)}
      />
      {stakeholderToBeRemoved && (
        <RemoveChallengeStakeholderModal
          challengeStakeholderId={stakeholderToBeRemoved.id}
          challengeStakeholderName={
            stakeholderToBeRemoved.stakeholder.full_name
          }
          open={Boolean(stakeholderToBeRemoved)}
          onHide={() => setStakeholderToBeRemoved(null)}
        />
      )}
    </Stack>
  );
};
